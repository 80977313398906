import React, { Component } from 'react';
import Header from '../../components/common/Header';
import Banner from '../../components/common/Banner';
import Footer from '../../components/common/Footer';

export default class Initiatives extends Component {

    render() {

        const AcademicInitiatives = [
            { Id: "1", Content: "Responsible for Starting Placement & Training Centre, Bangalore Institute of Technology" },
            { Id: "2", Content: "Responsible for Starting Post Graduate Program in Structural Engineering in the Department of Civil Engineering, Bangalore Institute of Technology" },
            { Id: "3", Content: "Responsible for Starting Research Centre in the Department of Civil Engineering, Bangalore Institute of Technology" },
            { Id: "4", Content: "Responsible for initiating NBA process for BIT for the first time" }
        ]

        const ProfessionalActivityInitiatives = [
            { Id: "1", Content: "Concrete Quality Circle through RMCMA" },
            { Id: "2", Content: "Professional chapters at educational institutes" },
            { Id: "3", Content: "Technical lectures/seminars/workshops for professionals" },
            { Id: "4", Content: "NufACE: Nursery for Action , Creativity and Excellence in association with RBSE" },
            { Id: "5", Content: "SME National Awards for Excellence for professionals" },
            { Id: "6", Content: "SPELL STAR, for students" },
            { Id: "7", Content: "Institutional chapters for ACCE (I), INSTRUCT, IIBE, ICI etc." },
            { Id: "8", Content: "Rotaract Club: Rotaract Bangalore BIT for creating social responsibility in students" },
            { Id: "9", Content: "Xkalibre Club for technical and personality development " },
            { Id: "10", Content: "TEDx" },
            { Id: "11", Content: "Computer Interface Club, for software and hardware skills" },
            { Id: "12", Content: "IEEE BIT student chapter" },
            { Id: "13", Content: "Western Musical group-“The ROCK BAND”" },
            { Id: "14", Content: "Indian Musical Group “UTKARSH”" },
            { Id: "15", Content: "The Theatrical Society-“NIVEDAN”" },
        ]

        return (
            <div>
                {/* Header */}
                <Header />

                {/* Banner */}
                <Banner image="banner/banner06.jpg" />

                <div className="container section-title with-desc clearfix"><br />
                    <div className="title-header"><h4 className="title">Academic Initiatives</h4></div>
                    <ul className="ttm-list ttm-list-style-icon">
                        {
                            AcademicInitiatives.map((item, i) => (
                                <li><i className="fa fa-check-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content">{item.Content} : <b>({item.Date}</b></span></li>
                            ))
                        }
                    </ul>
                </div>

                <div className="container section-title with-desc clearfix"><br />
                    <div className="title-header"><h4 className="title">Professional Activity Initiatives</h4></div>
                    <ul className="ttm-list ttm-list-style-icon">
                        {
                            ProfessionalActivityInitiatives.map((item, i) => (
                                <li><i className="fa fa-check-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content">{item.Content}</span></li>
                            ))
                        }
                    </ul>
                </div>

                {/* Footer */}
                <Footer />
            </div>
        )
    }
}
