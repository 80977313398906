import styled from "styled-components";
import { colors } from "../element/elements";

export const Styles = styled.div`
    .top-bar {
        height       : 40px;
        border-bottom: 1px solid ${colors.border1};
        background: linear-gradient(to left, ${colors.bg3} 50%, ${colors.bg4} 50%);

        .logo{
            padding-top   : 1.5rem;
        }

        .logo-name{
            color :  ${colors.bg1};
            font-size  : 1.3rem;
            border-bottom: 2px solid ${colors.bg1};
            padding-top   : 0.8rem;
            font-weight   : bold;
            margin-left   : -1.2rem;
            font-family   : cursive;
        }

        .logo-tagline{
            color :  ${colors.bg1};
            font-size     : 1.2rem;
            padding-top   : 0.5rem;
            margin-left   : -1.2rem;
            word-spacing  : 10px;
            font-weight   : bold;
            font-family   : cursive;
        }

        .bar-right {
            padding-top  : 0.2rem;
            height       : 39px;
            background: ${colors.bg3};
            border-radius: 0px 0px 0px 15px;

                ul.nav.menu-nav {
                    li.nav-item {
                        position: relative;

                        a.nav-link {
                            font-size     : 13px;
                            color         : ${colors.white};
                            text-transform: uppercase;
                            font-weight   : 500;

                            @media(max-width: 1199px) {
                                padding: 10px 2px 20px;
                                letter-spacing: 0;
                            }
                        }

                        ul.dropdown {
                            position  : absolute;
                            left      : 0;
                            top       : 100%;
                            min-width : 190px;
                            background: ${colors.bg3};
                            border    : 1px solid ${colors.border1};
                            text-align: left;
                            padding   : 0;
                            border-radius : 5px;
                            transition : 0.2s ease;
                            opacity         : 0;
                            transform       : scaleY(0);
                            visibility      : hidden;
                            z-index         : 999;
                            transform-origin: center top 0;
        
                            li.nav-item {
                                position: relative;
        
                                a.nav-link {
                                    font-size     : 13px;
                                    color         : ${colors.white};
                                    padding       : 10px 20px;
                                    text-transform: capitalize;
                                    font-weight   : 400;
                                    margin-right  : 0;
                                    border-bottom : 1px solid ${colors.border1};
        
                                    &:hover {
                                        color: ${colors.yellow};
        
                                        i {
                                            color: #fff;
                                        }
                                    }
        
                                    i {
                                        float     : right;
                                        margin-top: 4px;
                                    }
                                }
        
                                &:last-child {
                                    margin-left: 0;
        
                                    a.nav-link {
                                        border-bottom: none;
                                    }
                                }
        
                                &:hover {
                                    ul.dropdown2 {
                                        opacity   : 1;
                                        transform : scaleY(1);
                                        visibility: visible;
                                    }
                                }
                            }
                        }
        
                        &:hover {
                            ul.dropdown {
                                opacity   : 1;
                                transform : scaleY(1);
                                visibility: visible;
                            }
                        }
                    }        
                }

                ul.sidebar-button {
                    padding-top  : 0.2rem;
                    li.side-box {
                        a.nav-link {
                            font-size  : 20px;
                            padding    : 0;
                            line-height: 28px;
                            i {
                                color : ${colors.white};
                            }
                        }
                    }
                }
            }    
        }
        
        @media(max-width: 767px) {
            display: none;
        }
    }

    .header-area {
        height      : 110px;
        padding     : 1rem;
        background: ${colors.bg4};

        div.menu-box {
            ul.nav.menu-nav {
                margin-top : 1rem;
                
                li.nav-item {
                    position: relative;
                    text-decoration: none;

                    a.nav-link {
                        font-size     : 14px;
                        color         : ${colors.text6};
                        text-transform: uppercase;
                        font-weight   : bold;
                        padding       : 10px 10px 20px;

                        &:after {
                            content: none;
                            transform: scaleX(1);
                            position: absolute;
                            content: "";
                            width: 100%;
                            height: 3px;
                            top: 100%;
                            left: 0;
                            background: ${colors.bg3};
                            transition: transform 0.5s;
                            transform: scaleX(0);
                            transform-origin: left;
                        }

                        &:hover {
                            color: ${colors.bg3};
                        }

                        &:hover::after {
                            transform: scaleX(1);
                        }

                        @media(max-width: 1199px) {
                            padding: 10px 2px 20px;
                            letter-spacing: 0;
                        }
                    }

                    ul.dropdown {
                        position  : absolute;
                        left      : 0;
                        top       : 100%;
                        min-width : 200px;
                        background: #fff;
                        border    : 1px solid ${colors.border1};
                        text-align: left;
                        padding   : 0;
                        border-radius : 5px;
                        transition : 0.2s ease;
                        opacity         : 0;
                        transform       : scaleY(0);
                        visibility      : hidden;
                        z-index         : 999;
                        transform-origin: center top 0;

                        li.nav-item {
                            position: relative;

                            a.nav-link {
                                font-size     : 13px;
                                color         : ${colors.text1};
                                padding       : 10px 20px;
                                text-transform: capitalize;
                                font-weight   : 400;
                                margin-right  : 0;
                                border-bottom : 1px solid ${colors.border1};

                                &:hover {
                                    font-weight   : bold;
                                }
                            }

                            &:last-child {
                                margin-left: 0;

                                a.nav-link {
                                    border-bottom: none;
                                }
                            }
                        }
                    }

                    &:hover {
                        ul.dropdown {
                            opacity   : 1;
                            transform : scaleY(1);
                            visibility: visible;
                        }
                    }
                }
            }

            .apply-btn {
                margin-left: 70px;
                margin-top : 1rem;

                a {
                    font-size     : 13px;
                    color         : #ffffff;
                    background    : ${colors.gr_bg};
                    display       : inline-block;
                    width         : 117px;
                    height        : 40px;
                    text-align    : center;
                    text-transform: uppercase;
                    font-weight   : 500;
                    padding       : 11px;
                    border-radius : 0 5px 5px 0;
                    position: relative;

                    i {
                        position : absolute;
                        font-size: 20px;
                        left     : -40px;
                        top      : 0;
                        padding  : 10px;
                        width    : 40px;
                        height   : 100%;
                        border-radius : 5px 0 0 5px;
                        background-color: ${colors.green2};
                    }

                    &:hover {
                        background: ${colors.gr_bg2};
                    }
                }

                @media(max-width: 991px) {
                    display : none;
                }
            }
        }

        @media(max-width: 767px) {
            display: none;
        }
    }

    .top-bar-info{
        height: 3.25rem;
        background-Color: ${colors.bg3};
        text-align: center;
        color : ${colors.white};
        padding-top : 0.5rem;
        font-weight   : 500;

        ul {
            margin : center;
            
            a , li {
                font-size       : 13.5px;
                color           : ${colors.white};
                display         : inline-block;
                padding-top     : 0.2rem;

                @media(max-width: 1199px) {
                    margin-right : 8px;
                }

                @media(max-width: 991px) {
                    font-size : 13px;
                }

                ul.dropdown {
                    position  : absolute;
                    left      : 0;
                    top       : 100%;
                    min-width : 190px;
                    background: ${colors.bg3};
                    border    : 1px solid ${colors.border1};
                    text-align: left;
                    padding   : 0;
                    border-radius : 5px;
                    transition : 0.2s ease;
                    opacity         : 0;
                    transform       : scaleY(0);
                    visibility      : hidden;
                    z-index         : 999;
                    transform-origin: center top 0;
    
                    li.nav-item {
                        position: relative;
    
                        a.nav-link {
                            font-size     : 13px;
                            color         : ${colors.white};
                            padding       : 10px 20px;
                            text-transform: capitalize;
                            font-weight   : 400;
                            margin-right  : 0;
                            border-bottom : 1px solid ${colors.border1};
    
                            &:hover {
                                color: ${colors.yellow};
    
                                i {
                                    color: #fff;
                                }
                            }
    
                            i {
                                float     : right;
                                margin-top: 4px;
                            }
                        }
    
                        &:last-child {
                            margin-left: 0;
    
                            a.nav-link {
                                border-bottom: none;
                            }
                        }
    
                        &:hover {
                            ul.dropdown2 {
                                opacity   : 1;
                                transform : scaleY(1);
                                visibility: visible;
                            }
                        }
                    }
                }
            }
            
            .line:not(:first-child):before {
                content       : " | ";
                margin-right  : 1rem;
                margin-left   : 1rem;
            }

            &:hover {
                ul.dropdown {
                    opacity   : 1;
                    transform : scaleY(1);
                    visibility: visible;
                }
            }
        }
`;