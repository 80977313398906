import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import BackToTop from './BackToTop';
import { Styles } from "./styles/footer.js";
import { Link } from 'react-router-dom';

function FooterTwo() {

    return (
        <Styles>
            {/* Footer Two */}
            <footer className="footer2" style={{ backgroundImage: `url(${"https://shoonyeka.com/bit-website-production-3"}/assets/images/footer-bg.jpg)` }}>
                <Container>
                    <Row>
                        <Col md="3">
                            <div className="f-newsletter">
                                <div className="box-content">
                                    <h5>Get in Touch</h5>
                                    <p>
                                        Bangalore Institute of Technology,<br />
                                        K R Road, V V Pura, Bangalore, Karnataka, India. - 560004
                                    </p>
                                    <p>
                                        Affiliated to VTU,<br />
                                        Approved by AICTE and UGC,<br />
                                        Accredited by NBA<br />
                                        Accredited by NAAC with A+ Grade<br />
                                        CET Code:E008<br />
                                        COMED- K Code: E019<br />
                                        PGCET – MBA-B131, MCA-C411,<br />
                                        M. Tech-T812
                                    </p>
                                    <p>
                                        principalbit4@gmail.com<br />
                                        principal@bit-bangalore.edu.in
                                    </p>
                                    <p>+91 080 26615865</p>
                                </div>
                            </div>
                        </Col>
                        <Col md="7">
                            <div className="f-links">
                                <h5>Quick Links</h5>
                                <Row>
                                    <Col>
                                        <ul className="list-unstyled">
                                            <li><a target="_blank" href="https://shoonyeka.com/bit-website-production-3/admission-be"><i className="las la-angle-right"></i>Admissions</a></li>
                                            <li><a target="_blank" href="https://shoonyeka.com/bit-website-production-3/placement-about"><i className="las la-angle-right"></i>Placements</a></li>
                                            <li><a target="_blank" href="https://shoonyeka.com/bit-website-production-3/department-list"><i className="las la-angle-right"></i>Departments</a></li>
                                            <li><a target="_blank" href="https://shoonyeka.com/bit-website-production-3/teaching-and-learning"><i className="las la-angle-right"></i>Teaching and Learning</a></li>
                                            <li><a target="_blank" href="https://shoonyeka.com/bit-website-production-3/life@bit-bitcampus"><i className="las la-angle-right"></i>Life @BIT</a></li>
                                            <li><a target="_blank" href="https://shoonyeka.com/bit-website-production-3/downloads"><i className="las la-angle-right"></i>Downloads</a></li>
                                        </ul>
                                    </Col>
                                    <Col>
                                        <ul className="list-unstyled">
                                            <li><a target="_blank" href="http://rvsangha.org/"><i className="las la-angle-right"></i>Rajya Vokkaligara Sangha</a></li>
                                            <li><a target="_blank" href="https://shoonyeka.com/bit-website-production-3/assets/pdf/Home/Scholorship.pdf"><i className="las la-angle-right"></i>Scholarships</a></li>
                                            <li><a target="_blank" href="https://shoonyeka.com/bit-website-production-3/life@bit-studentclubs"><i className="las la-angle-right"></i>Student Clubs</a></li>
                                            <li><a target="_blank" href="https://shoonyeka.com/bit-website-production-3/life@bit-Sportsandgames"><i className="las la-angle-right"></i>Sports</a></li>
                                            <li><a target="_blank" href="https://shoonyeka.com/bit-website-production-3/alumni"><i className="las la-angle-right"></i>Alumni</a></li>
                                        </ul>
                                    </Col>
                                </Row>
                            </div>
                        </Col>
                        <Col md="2">
                            <div className="f-newsletter">
                                <h5 style={{ textAlign: "center" }}>Bitannica</h5>
                                <img src={"https://shoonyeka.com/bit-website-production-3" + "/assets/images/general/Bitannica.png"} alt="" className="img-fluid" />

                                <form id="form4" className="form paddingtop-2" style={{ textAlign: "center" }}>
                                    <a href="https://drive.google.com/file/d/1HJk6JDNv8hmPzY-GzP9G0a540WHC_meo/view?usp=sharing" target="_blank" style={{ color: "white" }}>Download Soft Copy</a>
                                </form>
                            </div>
                        </Col>
                        <Col md="12">
                            <div className="copytext-area text-center">
                                <p>Copyright &copy; 2022 | Designed &#38; Developed by <a href={"https://shoonyeka.com/bit-website-production-3" + "/"} target="_blank" rel="noopener noreferrer">Shoonyeka Solutions Mysuru</a></p>
                                <ul className="social list-unstyled list-inline">
                                    <li className="list-inline-item"><a href="https://www.facebook.com/bitsince1979" target="_blank"><i className="fab fa-facebook-f"></i></a></li>
                                    <li className="list-inline-item"><a href="https://twitter.com/bitsince1979" target="_blank"><i className="fab fa-twitter"></i></a></li>
                                    <li className="list-inline-item"><a href="https://www.instagram.com/bitsince1979/" target="_blank"><i className="fab fa-instagram"></i></a></li>
                                    <li className="list-inline-item"><a href="https://www.youtube.com/bitsince1979" target="_blank"><i className="fab fa-youtube"></i></a></li>
                                </ul>
                            </div>
                        </Col>
                    </Row>
                </Container>

                {/* Back To Top  */}
                <BackToTop />
            </footer>
        </Styles>
    );
}

export default FooterTwo
