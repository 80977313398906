import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import ScrollToTop from './helper/ScrollToTop';
import { GlobalStyle } from "./components/common/styles/global";

import Home from './Home';
import Gallery from './pages/Gallery/Gallery';
import Experience from './pages/Experience/Experience';
import Publications from './pages/Publications/Publications';
import Workshops from './pages/Workshops/Workshops';
import Initiatives from './pages/Initiatives/Initiatives';
import Associations from './pages/Associations/Associations';
import Recognition from './pages/Recognition/Recognition';

export default function App() {
    return (
        <Router>
            <GlobalStyle />
            <ScrollToTop />
            <Switch>
                <Route exact path={`${process.env.PUBLIC_URL + "/"}`} component={Home} />
                <Route exact path={`${process.env.PUBLIC_URL + "/gallery"}`} component={Gallery} />
                <Route exact path={`${process.env.PUBLIC_URL + "/experience"}`} component={Experience} />
                <Route exact path={`${process.env.PUBLIC_URL + "/initiatives"}`} component={Initiatives} />
                <Route exact path={`${process.env.PUBLIC_URL + "/publications"}`} component={Publications} />
                <Route exact path={`${process.env.PUBLIC_URL + "/workshops"}`} component={Workshops} />
                <Route exact path={`${process.env.PUBLIC_URL + "/associations"}`} component={Associations} />
                <Route exact path={`${process.env.PUBLIC_URL + "/recognition"}`} component={Recognition} />

            </Switch>
        </Router>
    )
}