import React, { Component } from 'react';
import Header from './components/common/Header';
import Footer from './components/common/Footer';
import PrincipalInfo from './pages/Home/PrincipalInfo';

export default class Home extends Component {
    
    render() {

        return (
            <div>
                {/* Header */}
                <Header />

                <PrincipalInfo />

                {/* Footer */}
                <Footer/>
            </div>
        )
    }
}
