import React, { Component } from 'react';
import { Styles } from './styles/banner.js';

export default class Banner extends Component {
    render() {
        return (
            <Styles>
                <section className="bitHeader-area" style={{ backgroundImage: `url(${process.env.PUBLIC_URL}/assets/images/${this.props.image})` }} />
            </Styles>
        )
    }
}
