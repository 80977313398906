import React, { Component } from 'react';
import Header from '../../components/common/Header';
import Banner from '../../components/common/Banner';
import Footer from '../../components/common/Footer';

export default class Workshops extends Component {

  render() {

    const ResearchProject = [
      { Id: "1", Content: "Mr. B.S. Putte Gowda(Completed)", RegisteredUnder: "VTU", Specialization: "Concrete Structures-High Volume Fly Ash" },
      { Id: "2", Content: "Mr.Raghavendra (Completed)", RegisteredUnder: "VTU", Specialization: "Concrete Technology-Curing Methods" },
      { Id: "3", Content: "Ms.Smita Singh (completed)", RegisteredUnder: "VTU", Specialization: "Geopolymer Concrete Products" },
      { Id: "4", Content: "Mr. Sayeed Sulaiman", RegisteredUnder: "VTU", Specialization: "Structural Engg./Concrete Technology" },
      { Id: "5", Content: "Mr. B S Karthik", RegisteredUnder: "VTU", Specialization: "Rock Mechanics" },
      { Id: "6", Content: "Mr. Madhan", RegisteredUnder: "VTU", Specialization: "Concrete Technology" },
      { Id: "7", Content: "Mr. Anjaneya Murthy", RegisteredUnder: "VTU", Specialization: "Tall Structures" },
    ]

    const ProjectWorksGuided = [
      { Id: "1", Content: "“Study on seismic behaviour of RC frame structures with plan and vertical irregularity” ( MEGHA C N, USN: 1BI15CSE07)" },
      { Id: "2", Content: "“Studies on shear strength of brick masonry with pond ash mortar” (ASWATHI V P, USN: 1BI14CSE01)" },
      { Id: "3", Content: "“Experimental Study on Red Mud Based Geopolymer in Ambient Temperature Curing”      (Rahul Das Biswas,USN: 1BI14CSE14)" },
      { Id: "4", Content: "Flexural Behavior of Reinforced Concrete Beams by  Partial Replacement of Sand With Granite Slurry” (ABHISHEK MAGA, USN: 1BI13CSE01)" },
      { Id: "5", Content: "Shear Behavior of Reinforced Concrete Beams Replacing GGBS as Cement and GBS as Fine Aggregate” (M C SAMBASIVAM, USN: 1BI13CSE10)" },
      { Id: "6", Content: "Experimental Investigation of Red Mud Geopolymer Bricks by partial replacement of sand with Granulated Blast Furnace Slag (GBFS)”     (Prajwala B Avarebeel USN: 1BI13CSE14)" },
      { Id: "7", Content: "Experimental Study on Red Mud Based Geopolymer Bricks”    (Ananya. A, USN: 1BI13CSE04)" },
      { Id: "8", Content: "An Experimental Investigation on Partial Replacement of Fine Aggregates by Granite Slurry in Hollow Concrete Blocks”    (Ambrutha A, USN: 1BI13CSE02)" },
      { Id: "9", Content: "Experimental and Analytical Studies on Behaviour of Reinforced Hollow Concrete Block Masonry in Shear” (Ashwin Thammaiah K, USN: 1BI12CSE02)" },
      { Id: "10", Content: "“Estimation of Compliance Function and Long Term Deflection of Reinforced Concrete Slabs – Some Comparative Studies” (CHAITHRA B. E., USN: 1BI12CSE03)" },
      { Id: "11", Content: "Development of Design Charts for Axial Load and Moment Capacity for Reinforced Hollow Concrete Block Masonry” (PRATYUSHA MOHAN NAIK, USN: 1BI12CSE11)" },
      { Id: "12", Content: "“Experimental Investigations on Red Mud Based Geopolymer Mortars” (Mahesh H P USN: 1BI11CSE08)" },
      { Id: "13", Content: "“Strengthening And Rehabilitation of RC Beams with Openings Using CFRP”, (Mudassir Shoeb, USN: 1BI11CSE11)" },
      { Id: "14", Content: "“Study on Red Mud Based Geopolymer Paste and its Applications” ( Basavana Gowda S N, USN: 1BI11CSE01) " },
      { Id: "15", Content: "“Experimental Investigation Of Flexural Strength Of Steel Fibers Over Polyester Fibers In Reinforced Concrete Beams” ( Manjunath.K.N, USN: 1BI09CSE07)" },
      { Id: "16", Content: "“Strength Studies of Red Mud Based Geopolymer Concrete” (Abhishek H N., USN: 1BI10CSE01)" },
      { Id: "17", Content: "An Experimental Study On Flexural Behaviour Of Reinforced Geopolymer Concrete Beams With Recycled Aggregates (Sanjay. R, USN:1BI10CSE13)" },
      { Id: "18", Content: "Experimental Study on Flexural Behaviour of Fly Ash and GGBS Based Geopolymer Concrete Beams in Bending” (Pradeep J., 1BI09CSE10)" },
      { Id: "19", Content: "Experimental investigation on behavior of concrete with the use of granite fines (Divakar Y., USN:1BI06CSE04)" },
      { Id: "20", Content: "Experimental studies on mortars and Shear strength of masonry wall (Arun Kumar  S. R.,USN: 1BI08CSE04)" },
      { Id: "21", Content: "Flexural behavior of reinforced concrete beams using steel mill scale as replacement for fine aggregate (Byresh B.G, USN: 1BI08CSE05)" },
      { Id: "22", Content: "Load carrying capacity of hollow clay block masonry columns-an experimental study (Adana Gowda, USN:1BI07CSE 01)" },
      { Id: "23", Content: "An experimental investigation to study the compressive strength of hollow clay block columns  (Ganesh Kumar.K.-USN:1BI07CSE 02)" },
      { Id: "24", Content: "Studies on flexural strength of reinforced hollow clay block beams (Remya R Nair USN:1BI07CSE11)" },
      { Id: "25", Content: "Strength and elastic properties of reinforced and unreinforced hollow clay block columns (Shamantha H.M.-USN:1BI07CSE 13)" },
      { Id: "26", Content: "Seismic response of transmission line towers including the effect of soil structure interaction (Shashikanth P Kodi-USN:1BI07CSE 15)" },
      { Id: "27", Content: "An experimental investigation on behaviour of in filled rectangular column in axial compression (Manjunath.S -USN: 1BI 06 CSE 05) " },
      { Id: "28", Content: "Computer aided analysis and structural optimization of transmission line tower (T.Raghavendra-USN:1BI 06 CSE 07) " },
      { Id: "29", Content: "An experimental investigation on composite circular column in axial compression (Smita Sing-USN:1BI 06 CSE 09)" },
      { Id: "30", Content: "Response evaluation of steel transmission tower under earthquake loading (K.N.Sharath-USN: 1BI 05 CSE 08) " },
      { Id: "31", Content: "Studies on pultruded polyester fly ash composite elements for structural applications. (Somashekar. R.-USN: 1BI 05 CSE 09) " },
      { Id: "32", Content: "Behaviour of Reinforced High Volume Flyash Concrete Slabs Under Flexure- An Experimental Investigation (Anzar. A-USN: 1BI 04 CSE 01)" },
      { Id: "33", Content: "Experimental studies on shear strength of brick masonry wall elements with opening (Fahad.  C.A. - USN:1BI 04 CSE 02) " },
      { Id: "34", Content: "Experimental studies on shear strength of brick masonry wall elements without opening (Murali H.S. –USN: 1BI 04 CSE " },
      { Id: "35", Content: "To evaluate the strength and behavior of fibre reinforced concrete deep beams at early stage (Mohan L-USN:1BI03CSE 06)" },
      { Id: "36", Content: "Studies on Infiltrated Fibrous Concrete(SIFCON) (Sreenivas S.R.-USN: 1BI 03 CSE 13)"},
      { Id: "37", Content: "Operation Research in Building Materials with a Detailed Study on Clay Blocks"}
    ]

    const TechTalks = [
      { Id: "1", Content: "25-26, February 2016, Two day National Seminar cum Deminar on ‘Concrete Admixtures and Waterproofing Systems’, Nimhans convention center, Bangalore"},
      { Id: "2", Content: "10-02-2016, Technical lecture delivered on “Quality of Concrete in Construction” at New Horizon College of Engineering, Bangalore"},
      { Id: "3", Content: "5-01-2016,Technical lecture on High Volume Fly Ash Concrete – Canadian experience by Dr. Ramesh C Joshi, Ph.D., P.Eng ,Professor Emeritus of Civil Engineering, The University of Calgary"},
      { Id: "4", Content: "24-11-2015 , Technical lecture on What are Smart Cities?” by Mr. B S C Rao,  Senior Consulting Engineer, Bangalore"},
      { Id: "5", Content: "19-11-2015, A “Brain-storming discussion meet on Geopolymers” by Dr. N P Rajamane, Head-Centre for Advanced Concrete Research, SRM University."},
      { Id: "6", Content: "18-11-2015, ZW CAD classic - a true cost effective drafting & design tool by Mr. JACOB, CEO, Hope Technologies Pvt Ltd, Bangalore"},
      { Id: "7", Content: "17-10-2015, Endowment Lecture- New Generation Construction Aids by DR. R. Nagendra, Technical Director,Civil-Aid Technoclinic Pvt Ltd,(A Bureau Veritas Group Company)"},
      { Id: "8", Content: "14-10-2015, Technical lectures on admixtures, waterproofing and  case studies by Mr. Santhosh Prakash, Mr. Sameer Katti and Mr. SHASHI NAIR, Proprietor, M/s. Exillis Trading And Décor."},
      { Id: "9", Content: "20-06-2015, Technical lecture on “ARE WE SENSITIVE TO THE SOCIETY?” at Civil Engineering Department, CHRIST UNIVERSITY, Kengeri Campus, Mysore Road, Bangalore: 560060 "},
      { Id: "10", Content: "18-06-2015, Technical lecture on “Alternatives to River Sand” By Dr. M. U. Aswath at ICI, Hotel Capitol, Bengaluru"},
      { Id: "11", Content: "16-05-2015 , delivered a technical lecture on RMC field problems & importance of RMC plant certification at One-day training program at MAHAVEER Group"},
      { Id: "12", Content: "4-06-2015, Lecture on Occupational Health Hazard for Civil Engineers and the Management by Dr. Prasanth. S Acharya, BAMS, MS (Ayu), Ph.D FICA (USA) FAGE in association with ICI-KBC and ACCE(I)"},
      { Id: "13", Content: "May 31 – June 2, 2015 Indo-Norwegian Training Program on “Seismic Design of Multi-Storey Buildings: IS 1893 vs. Eurocode-8”  at BIT, Bangalore for Practicing Structural & Geo-technical  Engineers, Designers, Academicians, Researchers and PG students in association with NORSAR, Kjeller, Norway, CSIR Centre for Mathematical Modeling & Computer Simulation  (C-MMACS), Bangalore, BMS College of Engineering, Dep. of Civil Engineering, Bangalore. Supported by Royal Norwegian Embassy to India, New Delhi."},
      { Id: "14", Content: "7th May 2015, Inauguration of American Society of Civil Engineers (ASCE) Chapter at BIT, by Prof. G L Sivakumar Babu, Professor, Geotechnical Engineering Division, Department of Civil Engineering Indian Institute of Science, Bangalore-560012 and Technical Lecture on “Eco friendly constructions” by Er. Ajit sabnis, CMD at InCiCon AG"},
      { Id: "15", Content: "23rd March 2015, World Water Day Celebrations and Lectures On “Water Conservation and Management” by Mr. Shivakumar A R, Senior Fellow,KSCST, IISc  and Mr.Goutam Surana, Founder,NEO SYSTEK, Innovative Solutions."},
      { Id: "16", Content: "16th March 2015, Seminar On “SMEs: Challenges of Growth” and SME National Awards at The Chancery Pavilion, Bengaluru- 560025 in association with Rotary BSE, SME World, New Delhi. CHIEF GUEST:Mr. Ravindra Nath,CMD, NSIC, Ministry of MSMEs, Govt. of India,GUESTS OF HONOUR: Rtn. Manjunath Shetty,Dist. Governor, RID 3190, Dr. H.P. Kumar, Former, CMD, NSIC, Ministry of MSMEs, Govt. of India,Mr. Babu Dhammanagi,Chairman, Dhammanagi Group of Companies, Mr. M. Basheer, Hamriyah Free Zone Authority, Govt. of Sharjah"},
      { Id: "17", Content: "13-01-2015, Technical Lecture on “Automated Operation of Canal System – Case Study of Sardar Sarovar Project” by Shri K S Srinivas, Rtd. Chief Engineer in association with ACCE(I)"},
      { Id: "18", Content: "30th December 2014, “ Project site visit of M/s B. G. Shirke Construction Technology Pvt. Ltd: Affordable Housing Project - Construction of LIG 2 BHK and MIG 3 BHK ,B+S+18 upper floors in prefab technology, Total 648 tenements in 7 towers  in association with ACCE(I)"},
      { Id: "19", Content: "20th October 2014, “ROUND TABLE MEETING on QUALITY CONSTRUCTION” at Bangalore to discuss the various concerns regarding quality in concrete construction. This Round Table is an initiative of Quality Council of India (QCI) and is supported by Ready Mixed Concrete Manufacturers Association of India (RMCMA)."},
      { Id: "20", Content: "10-10-2014, “Earthquake Hazard and Risk Reduction an Indo Norwegian Collaborative Project Towards Urban Earthquake Resilience in Indian Subcontinent by Dr. Abdelghani  a renowned researcher in earthquake engineering, working for NORSAR, (www.norsar.no) Norway"},
      { Id: "21", Content: "2nd August 2014 “Inspection, Repair and Monitoring of Flyovers & Bridges”, At The Institution of Engineers (India),Bangalore – 560001 in association with IEI, IIBE, BBMP, KRDCL, BDA, RMCMA and BIT"},
      { Id: "22", Content: "25th  June  2014, technical evening lecture on  “Importance of Advanced Surveying Technologies Like Total Station, DGPS And LiDAR For Practicing Civil Engineers” –By S. Hemanth Reddy ,CEO of Sir M V Institute of Engineering Skills (A Madhusiri Group Training & Placement Venture for Civil Engineers) at BIT in association with ICI-KBC and ACCE(I)"},
      { Id: "23", Content: "20.06.2014, technical evening lecture on Solutions to construction Industry in Waterproofing, Repair and Industrial Flooring segment.” - By  Mr. Raghunandhan Achar- Deputy General Manager, Central Technical Services, Ardex Endura India Pvt Ltd., Bangalore. And Kishore Hoysal .S-Asst General Manager, Key Accounts – Industrial Flooring Ardex Endura India Pvt Ltd, Bangalore at BIT in association with ICI-KBC"},
      { Id: "24", Content: "12.06.2014, technical evening lecture on “Flood Studies Using Mathematical Models”  by Srinivasa V Chakragiri, Professor and Head, Department of Civil Engineering, Global Academy of Technology, Bangalore at BIT in association with ICI-KBC and ACCE(I)"},
      { Id: "25", Content: "31st May 2014, “Rotary BSE-SME National Awards for Excellence” and a special interactive seminar on “SMEs IN INDIA”, at The Lalit Ashok, Bangalore: 560001, SPECIAL INTERACTIVE SEMINAR on “SMEs IN INDIA”, “Quality: The Way Forward for SMEs” By Dr G.J. Gyani, Director General, Association of Healthcare Providers (India], Availability .vs. Affordability - The consumer in Tier 2-3 cities By Mr. Ravindra Krishnappa-Founding Partner VertEXperts Consulting LLP, “Strategy Execution through people; 6 success routines of high-performance execution” By Mr. Ramesh Dasary, Change Management Consultant"},
      { Id: "26", Content: "30th  May 2014, Technical Lecture on “Concepts of Lean Construction”  by  Mr. Sampath Parthasarathy - Secretary General, ILCE, Mumbai  and “Application of Lean Construction in India with Case Studies”,  by Mr. Kalyan Vaidyanathan - CEO, Nadhi Information Technologies, Chennai  at BIT in association with ILCE, ACCE, RMCMA and ICI"},
      { Id: "27", Content: "30.04.2014, Technical Lecture on   “Hydro Politics: Conflicts and Co-Opeartion In River Basin by Dr. DATTATRI JADE, Retd. Professor, NITK Surathkal Visiting Professor & Engineering Consultant, Bangalore in association with ACCE(I)"},      
      { Id: "28", Content: "10th  March 2014, “sanitation options/ Sustainability in sanitation and water-Building a house incorporating all ecological aspects-truly a sustainable house”  by Dr. Jan-Olof Drangert , Senior Researcher, Dept of Water & Environmental Studies, Linköping University, SWEDEN and Dr. H.C. Sharatchandra, Former Chairman, Karnataka State Pollution Control Board (KSPCB) was the Guest of Honour [ 2 sessions: 11.15 AM 1.00 PM for students and 6.00pm to 9.00 pm for consultants] "},     
      { Id: "29", Content: "25 -26 Feb 2014, “Concrete Deminar and Panorama- “Innovative concrete materials and technologies”  by ICI-KBC, [Editor for the Technical Document]"},
      { Id: "30", Content: "20th  February 2014, “Advances in Human-Computer Interaction” by Dr Tony Stockman, Senior Lecturer, Electronic Engineering and Computer Science, Queen Mary University of London at BIT"},
      { Id: "31", Content: "20th  February 2014, “Bangalore Baseline for the Great Trigonometric Survey (GTS) of India” by Udaya Kumar P L ,IIT Madras, at BIT"},
      { Id: "32", Content: "10-02-2014, Engineering Sustainability: Engineers as Leaders in Facing Global Change By Dr Jonathan Bridge an environmental engineering scientist, University of Liverpool, Liverpool, UK "},
      { Id: "33", Content: "10-02-2014,  “Crowd sourcing for Building Attribute Information in Bangalore” by Anju Gupta, Ph.D., Sr. Director, Data Product Management, RMS"},
      { Id: "34", Content: "Dec 13th, 2013, “Alternatives to river sand A Sustainable approach” at Karnataka State pollution Control Board by ICI, KBC, Bangalore.[ Editor in Chief for the Technical Document]"},
      { Id: "35", Content: "8-12-2013, “Rapid chess Tournament” at BIT  in association with Rotary Bangalore South East"},
      { Id: "36", Content: "7-12-2013, Yakshagana and Haasya Sanje by Sri. Krishnamurthy Tunga & Team and Smt. Sudha Baragur, at BIT in association with Rotary Bangalore South East"},
      { Id: "37", Content: "30-10-2013, technical lecture on “Innovation and Values” by Dr. Somik Raha, Smart org at BIT,Bangalore-560004"},
      { Id: "38", Content: "29.Nov.2013, Friday, technical evening lecture on Construction of Al-Hamra Tower in Kuwait by Mr. N. Padmanaban at  BIT , K.R.Road, V.V.Puram Bangalore in association with Indian Concrete Institute-Karnataka Bangalore Centre(ICI-KBC)"},
      { Id: "39", Content: "25-08-2013, An inter-school spelling contest, SPELLSTAR 2013 -to foster the spirit of spelling and grammar among school going children, at BIT, Bangalore in association with Rotary Bangalore South East "},
      { Id: "40", Content: "4th April 2013, Technical Lecture on TTG Systems: Technology and Methodologies, by Brian S Howells, Architect,President & CEO & Mr. Graham Stewart,Structural and Civil Engineer,AECOS, Ltd., Murray Hill, USA at Bangalore Institute of Technology, K.R.Road, V.V.Puram, Bangalore 560004."},
      { Id: "41", Content: "On 23rd January 2013, a technical talk on “Benefits of Fine & Ultra Fine Pozolonic Material in High Performance Concrete - Indian Innovations” by Dr. N V Nayak; Principal Advisor, Gammon India Limited, Managing Director, Gammon Realty Limited and Chairman, Geocon International Pvt. Ltd. at Bangalore Institute of Technology, K.R.Road, V.V.Puram, Bangalore 560004."},
      { Id: "42", Content: "On 9th of January 2013 a technical talk/interaction program was held at Hotel Chalukya, Bangalore on “Guidelines on Quality control and quality assurance of Ready Mix Concrete” for about 80 Field Engineers. The program was organised by Ultratech. Speaker: Dr.M.U.Aswath."},
      { Id: "43", Content: "14th December 2012; Technical Lecture Series; a talk on “Concrete and Design Engineers Dilemma in Detailing for Durable Constructability” was delivered by Er. Umesh B Rao, Former President of Association of Consulting Civil Engineers (India) and a Well known Structural Consultant "},
      { Id: "44", Content: "22-11-2012, Technical Lecture Series; a talk on “CONCRETE RELATED STRUCTURES-An insight with respect to: Quality Criteria and Types of Tests” was delivered by Dr. ASWATH M U, Professor, Bangalore Institute of Technology at VOLVO RASTA for PWD Engineers of Government of Karnataka. About 70 Engineers of QA/QC departments were present."},
      { Id: "45", Content: "8th November2012, “Use of Readymade Steel in the Construction Industry” by Mr. Sunil George, ED&COO, Green Readymade Steel Products India Pvt. Ltd.,  at  Bangalore Institute of Technology in association with ACCE(I)  "},
      { Id: "46", Content: "8th November2012,  “Use of  Couplers in the Construction Industry” by  Mr. Ashish Jadav, GM, Halfen Moment India Pvt. Ltd., at  Bangalore Institute of Technology in association with ACCE(I)  "},
      { Id: "47", Content: "RMC Plant visit was organised for all the PWD engineers on 21-11-2012."},
      { Id: "48", Content: "31st October 2012, Technical Lecture Series a talk on “Check Lists for Quality Building Construction Part-I” Dr. ASWATH M U, Professor ,Bangalore Institute of Technology at BIT, Bangalore for field engineers and students"},
      { Id: "49", Content: "5th October 2012, Orientation talk was given to about 80 members of J.P.Nagar Rotary Club, Bangalore on “Managing Buildings for Peace” during one of the weekly meeting on by Dr. Aswath M U"},
      { Id: "50", Content: "28th September 2012, CIDC Interaction Program with Dr. P. R. Swarup, Director General CIDC at BIT, Bangalore"},
      { Id: "51", Content: "28th September 2012, Using Ready Mix Concrete For Precast/Prestressed Concrete Elements at Site by Mr. Ravishankar JB,President, GRIPSINDIA at BIT, Bangalore"},
      { Id: "52", Content: "29th August 2012, “Recent Developments in Remedial Engineering and Role of RMC” by Mr. M N Ramesh, C.E.O, Savcor India Private Limited at BIT, Bangalore"},
      { Id: "53", Content: "26th August 2012, An inter-school spelling contest, SPELLSTAR 2012 -to foster the spirit of spelling and grammar among school going children, at BIT, Bangalore in association with Rotary Bangalore South East "},
      { Id: "54", Content: "26th August 2012, “Counseling Session for Parents and Teachers” by Ms. Jyothi Sharma and Ms. Aninditha Mishra organised at BIT in association with Rotary Bangalore South East "},
      { Id: "55", Content: "26th August 2012, Interactive program to students for Instilling social values by Rtn. Fazal Mahmood (Alumni of BIT) at BIT, Bangalore"},
      { Id: "56", Content: "14 -15th August 2012, “Drawing competitions” to Govt. School students along with BIT Rotaract Club"},
      { Id: "57", Content: "11th August 2011, “Technical Presentation on Sustainable Concretes with Metakaolin and High Performance admixtures” by Mr. V R Kowshika, Bangalore at BIT, Sponsored by Ecmas Construction chemicals Pvt Limited, Hyderabad., in association with ACCE(I)"},
      { Id: "58", Content: "17, 18, 19 & 20 July 2012; Training & Workshop on “Road Safety, Development and Work Zone Safety Audits” For National Highways Authority of India (NHAI),  organised at BIT in association with HAKS Engineers, Architects and Land Surveyors P.C., InfoTrans Engineers Pvt. Ltd. and Rotary Bangalore South East"},
      { Id: "59", Content: "9th July 2012, Basics for Quality Ready Mixed Concrete and RMC Plant visit, Mr. Raviprakash [Alumni of BIT] –Manager Technical and his team, RMC Readymix (India)"},
      { Id: "60", Content: "12th June 2012, “Basics for Quality Ready Mixed Concrete”  by Mr.R.Nagendra, Technical Director, Civil-Aid Technoclinic Private Limited, BUREAU VERITAS GROUP (INDIA)"},
      { Id: "61", Content: "14th May 2012, “Making Good Concrete” by Mr.Suresh Rao, GM-Technical, M/s RMC Readymix (India) at Bangalore Institute of Technology"},
      { Id: "62", Content: "11th February 2012, Inauguration of “Nu fACE, Nursery For Action, Creativity And Excellence” for a better tomorrow in association with Rotary Bangalore South East"},   
      { Id: "63", Content: "30Th-31st January 2012, “Faculty Development Program on Civil FEM” in association with VTU and Innovent, at BIT , Bangalore"},
      { Id: "64", Content: "14th December, 2011, “World Trade Center Building Disaster: Lessons for Engineering Response”  by Dr. Venkatesh Kodur, F.ASCE, F. NAE (India),Professor and Director, Center on Structural Fire Engineering and Diagnostics, Department of Civil & Environmental Engineering, Michigan State University, East Lansing, MI, USA organised by ACCE(I), ASCE and BIT at BIT, Bangalore"},
      { Id: "65", Content: "6th  December, 2011, Technical evening lecture “Planning, Design and Seismic Shake table testing of a Six Storeyed full scale Building” by Professor John W. van de Lindt, Ph.D., organised at BIT in association with ACCE (I). About 60 civil engineering consultants and some students were present for the presentation"},
      { Id: "66", Content: "19th October 2011, “Training program on MSP course” by ANP CPMC Pvt. Ltd., S-CME at BIT, Bangalore"},
      { Id: "67", Content: "18th, 19th and 20th of November  2011,Three day training program “The Seismic resistant design of buildings using STRUDS 2011” in association with CSC for consultants, faculty and students at BIT, Bangalore"},
      { Id: "68", Content: "3rd November 2011, Technical Presentation on “Decentralized Wastewater Treatment System (Dewats)” by Mr. Rajesh Pai, Manipal at BIT, Bangalore in association with ACCE(I)"},
      { Id: "69", Content: "16th September 2011, “Engineer’s Day Celebrations – 2011” Sri. A.N. PRAKASH, Managing Director, A.N. Prakash Construction Project Management Consultants Pvt. Ltd. and Sri. G.H.BASAVARAJU, Managing Director, Chetana Consultants, Bangalore addressed the students and faculty of BIT"},
      { Id: "70", Content: "28th August 2011, SPELLSTAR 2011 organised in association with Rotary Bangalore South East at BIT, Bangalore."},
      { Id: "71", Content: "6th August 2011,Presentation to the Brigade Group, during their Engineer’s meet on  “Concrete Making and Quality Principles of RMC”, by Dr.Aswath M U, at world trade centre,  Galaxy Club, Banquet Hall-2,  Brigade Gateway Campus, No.26/1, Industrial Suburb ‘A’ Block, Dr.Rajkumar Road, Subramanyanagar,  Malleswaram West, Bangalore .More than 70 engineers were present."},
      { Id: "72", Content: "9th June 2011, “Technical Presentation on Next Generation in RC Design and Detailing”  by Mr. Sajit V R Nair, CEO of S-cube Technologies at BIT sponsored by S-cube Technologies, in association with ACCE(I) "},
      { Id: "73", Content: "5th May 2011, “ Technical Presentation on CYPE-Software for Architecture, Engineering and Construction”  by  Mr. Amarnath S N, Structural Engineer at BIT sponsored by Cype Software for architecture engineering & Construction, & FE Designs, in association with ACCE(I)"},
      { Id: "74", Content: "24th February 2011, organised and chaired a conference session at BIEC, Bangalore during the “Build Arch 2011 & Build Floor 2011”. The Conference lectures included Modern Concretes, Formwork and Non Destructive Testing by Mr.R.Nagendra, Mr. T.S.Gururaj and Mr.M.S.Sudarshan respectively"},
      { Id: "75", Content: "22nd   February 2011, Technical Lecture on “EUGÈNE FREYSSINET-His Incredible Journey to Invent and Revolutionize Prestressed Concrete Construction” by Mr. C. R. Alimchandani, Chairman & Managing Director, STUP Consultants Pvt. Ltd, Mumbai at Century Club, Cubbon Park, Bangalore in association with Association of Consulting Civil Engineers (India),Indian Institute of Bridge Engineers, Indian Concrete Institute and Bangalore Institute of Technology"},
      { Id: "76", Content: "29th January 2011, Technical Lectures: “ Developments in New Construction Materials”        by Dr. V.Ramakrishnan, Professor, Technological University of South Dakota USA, and “Recent Fibre Reinforced Concrete Constructions” by Mr. Clifford N McDonald at BIT, Bangalore in association with ACCE (I), ICI-KBC and UltraTech"},
      { Id: "77", Content: "22nd December 2010, A Technical Lecture on “Structural Strengthening of Concrete Structures using CFRP Components” by Dr. Gopal Lalji Rai, CEO, R & M International, Mumbai"},
      { Id: "78", Content: "19th May 2010, A Technical lecture on “New Generation Codes for Steel Work Design-A move towards Limit State Design” by Mr. Amitabha Ghoshal, STUP Consultants, Mumbai in association with ACCE(I)"},
      { Id: "79", Content: "30th  April 2010, A Technology Workshop on “ Bridge Software for analysis and design” by MIDAS India"},
      { Id: "80", Content: "20th April 2010, A Technical Lecture on “Change Management”  by Dr. Gururaj Karajagi, former member of the Syndicate, Senate and Academic council ,Bangalore university in association with ACCE"},
      { Id: "81", Content: "13th April 2010, Coordinated the Visit of Sterling Group- UK, Member Dr Sreejith V Nanukuttan, School of Planning, Architecture and Civil Engineering, Queen's University of Belfast to BIT Civil Engineering Department for an interaction with students and faculty on “Excellence in Engineering Teaching and Research”"},
      { Id: "82", Content: "12th April 2010, a Technical Lecture on “Innovation in Seismic Bracing Design”                by Sri. B.N.Sridhara, Former Director-SEMAC in association with ACCE"},
      { Id: "83", Content: "9th March 2010, a Technical Lecture on “Design Aspects of Tall Buildings” by Shri. S.N.Manohar, Former Chief Civil Engineer, TaTa Consulting Engineers in association with ACCE(I)"},
      { Id: "84", Content: "6th March 2010, A Technical Discussion on “Construction Without Disasters” organized by IEI and IIBE( Organizing and Program Moderator)"},
      { Id: "85", Content: "5-6 March 2010, All India seminar on “Eco-Architecture and Green Buildings” organized by The Institution of Engineers India –Karnataka State centre. (Seminar organizing Secretary)"},
      { Id: "86", Content: "11th February 2010, A Technical Lecture on “Fly-Ash: The Essential Ingredient for Sustainable Concrete Construction” by Dr.Moray Newlands, Concrete Technology Unit, Division of civil Engineering, University of Dundee, Scotland, UK in association with ACCE(I) and RMC Readymix(India) Pvt.Ltd."},
      { Id: "87", Content: "6th February 2010, a Technical Lecture on “Concrete Structures for Storage of Liquids - Code of Practice” Is 3370 (Part 1 & 2): 2009 by Shri. L K Jain, Past President- ACCE(I) & Consulting Structural Engineer, Nagpur in association with ACCE(I)"},
      { Id: "88", Content: "12th January 2010, Structural Engineers meet and Technical Presentation on “Urban Elevated Transport Structures”  by Mr.A.T.Samuel Executive Director , STUP Consultants organised by ACCE(I)"},
      { Id: "89", Content: "6th January 2010, A Technical Lecture on “Lessons from Case Studies: Geotechnical Aspects” by Prof. A.Sridharan, Professor-Emeritus, IISc, Bangalore in association with ACCE(I) "},
      { Id: "90", Content: "24th November 2009, Training Program on “Special Concretes and Their Applications” by Indian Concrete Institute Karnataka Bangalore centre Bangalore. The topics covered are: a. Special Concrete- an overview by Dr.R.Jagadish, b. Special Products the RMC way by Mr.Raj Kumar Pillai, c. High Performance concrete with Mix design Considerations by Mr. Nagendra, d. Self-Compacting Concrete –Presentation and Live demo by Mr.V.R.Koushika, e. Concluding presentation on special concrete by Dr.R.V.Ranghanath"},
      { Id: "91", Content: "4th November 2009, “Innovations in Structural Engineering and Ethics in Engineering The Tale of Teetering Tower” by Mr.Ravindra Mysore , President, Bill Lemessurier, USA in association with ACCE(I)"},
      { Id: "92", Content: "27th August 2009, A Technical Lecture on “Provisions and Issues in the Revised IS-1786-2008, High Strength Deformed Steel Bars and Wires for Concrete Reinforcement-Specification”  by Dr.C.S.Viswanatha, Chairman Civil Aid Techno clinic Pvt.ltd. in assocuiation with ACCE(I)"},
      { Id: "93", Content: "4th August 2009, A technical Lecture on “ Expansion Control Systems for Buildings” by Mr.Mukesh Singh,Head-ECS Div. BASF in association with ACCE(I)"},
      { Id: "94", Content: "24th July 2009, Technical lecture on, “Design of Concrete parking structure from concept to Detail Drawings preparation” by Mr.Raj Jalla, President and CEO, Consulting Engineers, Corp. Reston, VA-USA, Chairman IDES-India in association with ICI-KBC & RMC Readymix(India) Pvt.Ltd."},
      { Id: "95", Content: "11th May 2009,  “Damage assessment in Concrete Structure – Global and Local Methods” by Prof Antony Jeyasehar, Professor & Head, Dept of Civil & Strl. Engineering, Annamalai University in association with ICI-KBC"},
      { Id: "96", Content: "17th April 2009, A Technical Lecture on “High Performance and Ultra High Strength Concrete with PCE Technology in Asia Pacific” by Mr.Nilotpal Kar, Regional Manager, BASF. More than 200 consulting Engineers participated, organised  with ICI-KBC"},
      { Id: "97", Content: "24th December 2008, A Technical presentation on “ Health and Safety Measures at Construction Sites-A new Approach” by Mr.Prashanth Manukar in association with ACCE(I) and ICI-KBC"},
      { Id: "98", Content: "21-22 Nov’2008 “ days 2008” An International conference organized for Institution of Engineers(India) by IMC- fib, at Bengaluru (organizing committee Member)"},
      { Id: "99", Content: "15th November 2008, “Engineers Day Celebrations”. Mr.Nirmal Prasad, Chairman INSTRUCT and Mr.D.Ranganath, Structural Consultant addressed the students and faculty"},
      { Id: "100", Content: "5-11-2008, A Technical Talk on “Bengaluru Metro Rail Project” by Sri. B.S.Sudhir Chandra, Director (projects & planning), BMRCL at BIT, Bengaluru"},
      { Id: "101", Content: "15.09.2008 “Future For Civil Engineers” by Mr. A Nirmal Prasad & Mr. D Ranganath consultants Bangalore"},
      { Id: "102", Content: "12.03.2008 “High Strength Concrete” by Mr. Gordon Bain, Australia at BIT in association with ACCE."},
      { Id: "103", Content: "19th February, 2008, BIT Quadrangle: CONVERSATION WITH SUBROTO –open house:  One of the most cherished program for the students. It was an open house with no agenda and no speeches. Anybody can ask any question to Subroto Bagchi and his team of experts from MindTree. The program was anchored by the Placement officer"},
      { Id: "104", Content: "11-02-2008 “Masons Training Program” in association with Grasim industries. Dr.V.Ramachandra, Manager-Technical gave the perspective of the program"},
      { Id: "105", Content: "30.01.2008 “New Approach to Implement Building - Bye Laws” an interaction with consulting Engineers and Bangalore City Corporation Engineers at BIT in association with ACCE."},
      { Id: "106", Content: "27.10.2007 Technical Site Visit to “Bangalore International Airport”, Devanahally in association with ACCE."},
      { Id: "107", Content: "26.07.2007   “Professional Ethics in the Practice of Engineering” by Shri. L V Sreerangaraju, Former General Manager, KPCL, Advocate and Techno Legal Advisor in association with ACCE."},
      { Id: "108", Content: "28.06.2007  “Self-Curing Concrete” by Dr. C. S. Viswanatha ,Chief Executive, Tor Steel Research Foundation in India  at Bangalore Institute of Technology in association with ACCE."},
      { Id: "109", Content: "13.06.2007 Technical Site Visit to “Soil Retaining Works” at Adarsh Capitol, Near Mount Carmel College, Bangalore in association with ACCE."},
      { Id: "110", Content: "25.05.2006  ICI-TORSTEEL endowment lecture “Tsunami – Recent Threats” by Dr RAVI SINHA IIT, Mumbai at BIT Bangalore"},
      { Id: "111", Content: "22nd November 2002 one day workshop on “Recent Trends and Practices in Civil Engineering”, BIT, Bangalore"},
      { Id: "112", Content: "5, 6 and 7th of December 1997, MRIGATRISHNA-1997, An intercollegiate Technical Festival, the first of its kind among engineering colleges in Karnataka was conducted at Bangalore Institute of Technology"},
    ]

    const Workshops = [
      { Id: "1", Content: "25th to 27th March 2015 Three days National Conference on Recent Advancement in Infrastructural Development - RAID 2015 in collaboration with Indian Concrete Institute - KBC, Bangalore and Association of Consulting Civil Engineers (India), at JSSATE Bangalore." },
      { Id: "2", Content: "24-25, February 2015, Concrete Panorama & Deminar- 2015, National Seminar, Live Product demonstration and exhibition on Quality assurance and Innovations in ready mixed concrete, Bangalore. Organised by ICI, Karnataka, Bangalore centre." },
      { Id: "3", Content: "9th -13th November, 2014, International Seminar and Exhibition on Recent Developments In “Design and Construction of Precast Concrete Technology”, Organized by Association of Consulting Civil Engineers (India) Bangalore Centre" },
      { Id: "4", Content: "27-28, March 2014, National Seminar on Recent Research and Developments in Civil Engineering (RRDCE2014), Dr. Ambedkar Institute of Technology, Bangalore-560 056" },
      { Id: "5", Content: "28-29th Oct 2014.Concrete Fair 2014” organised at RVCE in association with ICI. All M Tech students also participated and won overall championship." },
      { Id: "6", Content: "16-17 December 2013, Abhivruddhi Karnataka- The Karnataka Economic Summit, World Trade Centre Bangalore, Organised by Bangalore Chamber of Industry and  Commerce" },
      { Id: "7", Content: "13 December 2013, one day seminar on Alternatives to River Sand-a sustainable approach, KSPCB, organised by ICI-KBC, Bangalore" },
      { Id: "8", Content: "18-19 November 2013, International Colloquium on Architecture-Structure Interaction for Sustainable Built Environment, India Habitat Centre, New Delhi organised by SEWC" },
      { Id: "9", Content: "20-21 November 2013, International Conference on Mission ‘Skilling India-Harnessing CSR for Skill Development of Youth, Scope Complex, New Delhi, organised by CIDC" },
      { Id: "10", Content: "8 November 2013, Indian Construction-A Paradigm Shift, Southern Convention, Builders Association of India, Karnataka Bangalore centre, Bangalore" },
      { Id: "11", Content: "26th & 27th April 2013, National Seminar on “Steel Structures-STEELCON”, S J College of Engineering, Mysore and ACCE(I)" },
      { Id: "12", Content: "1st March 2013, National Conference On “Project Management in Construction Sector”, NIMHANS Convention Centre, organised by INSTRUCT, Bangalore, Hosur Road, Bangalore" },
      { Id: "13", Content: "28th & 29th  January 2013, Two day National seminar, live product demonstration and exhibition “Concrete Panorama & Deminar 2013-Innovative &Decorative Concretes” organised by ICI-KBC, BMS college of Engineering, Bangalore-19" },
      { Id: "14", Content: "11-12th   January 2013, Seminar on “Innovative Infrastructure for Incredible India and A3C- Awards, Convention and Consultants Colloquium”, organised by ACCE(I) Mangalore Centre" },
      { Id: "15", Content: "19th to 24th November 2012, “Quality Improvement Program” by TASK Force for Quality Assurance in Public Construction-Govt. of Karnataka”, IR-RAASTA, Bangalore" },
      { Id: "16", Content: "11-12th  November 2012, “A3C- Awards, Convention and Consultants Colloquium with Technical Presentations by the Industry”, organised by ACCE(I) Nagpur  Centre" },
      { Id: "17", Content: "15th September 2012	One Day Workshop on “Geo-technical Investigation techniques and its interpretation for a viable foundation system” at Bunts Sangha Auditorium, Bunts Sangha Complex, Chord Road, Vijaya Nagar, Bangalore – 560 040 Organised by ACCE(I) Bangalore Centre." },
      { Id: "18", Content: "15th September 2012	“Engineers Day Celebration & Felicitation of Eminent Engineers” sponsored by: Madras Cements, organised by ACCE(I)" },
      { Id: "19", Content: "7th & 8th June 2012, “GIM-Global Investors Meet” Infrastructure Development Department, Govt. of Karnataka, Bangalore" },
      { Id: "20", Content: "25th May 2012	“Influence of Cement on Concrete Health” by Mr. C V Suvasan, Sr. G M-Tech, Dalmia Bharat Cement at Century Club Sponsored by Dalmia Bharat Cement, organised by ACCE(I)." },
      { Id: "21", Content: "28-30th December 2011, “6th Annual International Conference on Public Policy and Management” organised by Centre for Public Policy, Indian Institute of Management, Bangalore." },
      { Id: "22", Content: "15-18th December 2011, “26th Indian Engineering Congress” organized by The Institution of Engineers (India), Karnataka State Centre at Bangalore." },
      { Id: "23", Content: "9th December 2011, “Annual HR Conference 2011: HR for 2020-Driving Business Excellence by Managing People” organized by Confederation of Indian Industry at Bangalore." },
      { Id: "24", Content: "24th November 2011, “Study Circle Meet on Bangalore Footpaths – A Technical Review” at Instruct, Bangalore." },
      { Id: "25", Content: "22-23rd  October 2011, “Technical Excellence & General Exposure needed for Civil Engineers through Soft Skill Development & A3C- Awards, Convention and Consultants Colloquium”, organised by ACCE(I) Hyderabad Centre" },
      { Id: "26", Content: "16th September 2011, “Engineers Day Celebration & Felicitation of Eminent Civil Engineers” at Century Club Sponsored by Madras Cements Ltd., organised by ACCE(I)" },
      { Id: "27", Content: "7th July 2011 	“Study Circle Meet on Bangalore Drainage Problems and Engineers View” at Instruct, Bangalore." },
      { Id: "28", Content: "17th June 2011, “Pop-2011-Placement Orientation Programme” organised by ACCE (I) & Civil Engineering Department, Global Academy of Technology sponsored by Ultratech Cements Ltd." },
      { Id: "29", Content: "16th May 2011, “Technical Presentation on New  Products” Division, Dr. Sumon Chakravarthy, General Manager Mumbai at Century Club sponsored by UltraTech Cements Ltd.,  organised by ACCE(I)" },
      { Id: "30", Content: "21st April  2011, “Technical Presentation on Corrosion Mitigation of Reinforcement in Concrete Structures using Galvanic Principle”  by David W Whitmore, President, Vector Corrosion Technologies, Canada at Bangalore City Institute Sponsored by BDS Projects India Pvt. Ltd., organised by ACCE(I)" },
      { Id: "31", Content: "22nd - 24th  February 2012, “	National Seminar & Exhibition on “Recent Developments in Design and Construction for Repair, Rehabilitation and Retrofitting” at Convention Centre, NIMANS, Bangalore, organised by ACCE(I)" },
      { Id: "32", Content: "31st January 2011, seminar on “Energy Options for a Greener Future” organised by Confederation of Indian Industry at Bangalore, organised by ACCE(I)" },
      { Id: "33", Content: "29th January 2011, “Developments In New Construction Materials”  By Dr. V Ramakrishnan, Prof. Technological University Of South Dakota, Usa , organised by ACCE(I)" },
      { Id: "34", Content: "29th January 2011, “Recent Fiber Reinforced Concrete Constructions”  By Mr. Clifford N Macdonald." },
      { Id: "35", Content: "10th -12th  January 2011, “ Concrete Panorama & Deminar” - A 3 Day International Exhibition, Conference & Live Demonstration on Concrete Products, Machinery and Technology  conducted by Indian Concrete Institute, Karnataka, Bangalore Centre at Gayatri Vihar, Palace grounds, Bangalore." },
      { Id: "36", Content: "26th December 2010 to 2nd January 2011, Short term course on “Green Infrastructure” conducted by department of Civil Engineering, Indian Institute of Technology, Kharagpur." },
      { Id: "37", Content: "22nd December 2010, “Structural Strengthening of Concrete Structures Using CFRP Composites” by Dr. Gopal Lalji Rai, Chief Executive Officer, R&M International, Mumbai. Organised by ACCE(I)" },
      { Id: "38", Content: "5-9 December 2010, “Asian Conference on Ecstasy in Concrete-2010”, organised by Indian Concrete Institute in association with Indian Institute of Technology Madras at IIT Madras, Chennai." },
      { Id: "39", Content: "23-25 September 2010, Workshop on “Innovation” conducted by Dale Carnegie & Associates and Wipro at Port Blair, the Andaman and Nicobar Islands." },
      { Id: "40", Content: "17th September 2010, “Engineer’s Day Celebration & Felicitation Of Eminent Engineers” by ACCE(I),Sponsored By Madras Cements Ltd., Banglaore." },
      { Id: "41", Content: "27-28th  August 2010, “ACCE(I) Silver Jubilee Convention -2010  Theme : Civil Engineering in India – A Proud Journey in the Past Quarter Century” organised by ACCE(I) HQ & BLC" },
      { Id: "42", Content: "30th July 2010, National Seminar on “Quality Assurance and Buildability Issues in Concrete Structures” organised by ACCE(I)" },
      { Id: "43", Content: "26th May 2010, “Advantages of Pre Engineered Buildings over Conventional Buildings & Applications of Pre Engineered Buildings” By P. V. Rao President, Pebs Pennar, Programme Sponsored By: Pennar Engineered Building Systems Ltd." },
      { Id: "44", Content: "Conference and Exhibition on “Precast Concrete Technology-Redefining Mass Housing & Infrastructure in India” , February 18-19-20,2010 Yashada, Pune, India Organised by ICI-Pune Centre" },
      { Id: "45", Content: "National Seminar, Deminar and Exhibition on “ Recent Developments in Design and Construction”, 28-29-30th  January 2010, NIMHANS Convention centre, Bangalore organised by ACCE(I) Bangalore centre" },
      { Id: "46", Content: "16th December 2009, “ High Performance Walls & Energy Efficient Solutions for Indian Buildings”  by Mr. Ingo Hofmaier, Director Commercial, Wienerberger Brick Industry Pvt. Ltd." },
      { Id: "47", Content: "27th November 2009, “Inelastic Column Shortening Effect and Application on High-Rise Structures using MIDAS Software”  by Mr. Angshuman Pandey, MIDAS Research & Development Centre India (P) Ltd., Mumbai." },
      { Id: "48", Content: "29th October 2009, “Mechanical Splicing Coupler System for Steel Reinforcements” by Unitech Couplers India (P) Ltd., Coimbatore. Sponsored by UNITECH" },
      { Id: "49", Content: "30th September 2009, “Orientation cum Leadership Training Program”                             The Lectures included:" },
      { Id: "50", Content: "15th September 2009, “Engineer’s Day Celebration & Felicitation of Eminent Engineers” Sponsored by Madras Cements Ltd., Banglaore. Program by ACCE(I)" },
      { Id: "51", Content: "4th August 2009, “Expansion Control Systems for Buildings” by Mr. Mukesh Singh, Manager Asia Pacific, BASF Wabo Expansion Control Systems. Sponsored by BASF Construction Chemicals (I) Pvt. Ltd." },
      { Id: "52", Content: "15th June 2009, “New International Technology for Road Building” by Mr. John Winters, President, Romix Holdings Ltd., Sponsored by Romix Holdings Ltd.," },
      { Id: "53", Content: "2nd June 2009, “Seminar on “Innovative Plastic form work” by Mr.Jigesh Desai, President , Formuvation Engineers India at Bangalor" },
      { Id: "54", Content: "22nd May 2009, “Geo-Reference Model is a Solution for Construction, Real Estate and Infrastructure Industry” by Mr. Guru Prasad T N, CEO of UV animation." },
      { Id: "55", Content: "International Conference- “fib days 2008” on 21st &22nd November 2008, organized by IMC-fib for IEI at Bengaluru." },
      { Id: "56", Content: "National workshop on “Opportunities & Challenges for Civil Engineers in Today’s World” on 29th & 30th August 2008,organized by ACCE Dharwad Centre, Dharwad" },
      { Id: "57", Content: "National seminar on “Recent Developments in Design and Construction Technologies” 7th,8th & 9th August 2008, organized by ACCE, Bangalore" },
      { Id: "58", Content: "National Seminar on “ A 360o View of Civil Engineering” on 7th June 2008, organized by ACCE-Indore" },
      { Id: "59", Content: "National Seminar on “Engineered Concretes” on 28th & 29th March 2008, organized by Indian Concrete Institute Bangalore" },
      { Id: "60", Content: "National workshop on “ Fire Safety in Buildings”, 15th March 2008, organized by ICI, ACCE & International Institute of Information Technology- Hyderabad" },
      { Id: "61", Content: "National Seminar on “Hazardous Materials Management” , 4th & 5th February 2008, organized by ACHMM India chapter at Bangalore" },
      { Id: "62", Content: "International conference “ 3rd Structural Engineers world congress 2007 (SEWC)” -2-7 November 2007, Bangalore" },
      { Id: "63", Content: "17-18 August 2007, National Workshop on “Mechanization in Construction” organised by ACCE(I) Hyderabad Centre" },
      { Id: "64", Content: "Seminar on “ Hazardous Waste Management Rules” on 18th May 2007, organized by ACHMM-India chapter at BIT-Bangalore" },
      { Id: "65", Content: "Workshop on “Electroplating Waste Management” on 24th March 2007, organized by Academy of certified Hazardous material managers-India chapter at BIT Bangalore" },
      { Id: "66", Content: "National seminar on “Recent Developments in Design and Construction Technologies” -18th,19th & 20th January 2007, organized by ACCE, Bangalore" },
      { Id: "67", Content: "Workshop on “ Seismic Qualification of Electrical Equipment & Structures” on 23rd & 24th November 2006, organized by Earthquake Engineering and Vibration Research Centre-CPRI Bangalore" },
      { Id: "68", Content: "National seminar on “ Restructuring of Education to Meet the Global Industrial Needs” on 17th & 18th November 2006, organized by ISTE at BIT, Bangalore" },
      { Id: "69", Content: "Technical Seminar on “ Structural Euro codes-Concrete Structures & Earthquake Design” on 31st October 2006, organized by British Standards Institution at Bangalore" },
      { Id: "70", Content: "Infosys Camus Connect- “Soft Skills Training Program” from 7th -11th August 2006, conducted by Infosys Technologies Limited at Infosys ILI, Mysore" },
      { Id: "71", Content: "Six weeks of Training in “Construction Project Management” from 28th February to 8th April 2005 conducted by A.N.Prakash Construction Project Management Consultants Pvt. Ltd. , Bangalore" },
      { Id: "72", Content: "Faculty development program on “Human Resource Development” conducted by Indian Heritage Academy and Vokkaligara Sangha during 20th & 21st February 2004" },
      { Id: "73", Content: "Short Term Course on “Structural Masonry” held during 10th -15th November 2003 at Indian Institute of Science, Bangalore." },
      { Id: "74", Content: "Three day program on “Human Resource Development for principals and Senior faculty of Engineering colleges” during 6th to 8th May 2003 at BMSCE organized by VTU, AICTE & Indian Heritage Academy" },
      { Id: "75", Content: "Short Term Training Program on “Structural Steel Design for university Faculty” during 3rd to 8th February 2003 at department of civil engineering, Osmania University, Hyderabad" },
      { Id: "76", Content: "Workshop on “MATLAB for Scientists and Engineers” during August 22nd to 24th 2002 at Faculty of Civil Engineering, Bangalore University Bangalore." },
      { Id: "77", Content: "Seminar on “Structural Dynamics in Civil Engineering: Some recent development”    (SDCE-2002) during 18th & 19th July 2002, at IISc, Bangalore-560 012" },
      { Id: "78", Content: "Two Day Training Program on “ Concrete Mix Design and Quality Control” on 26-27 April 2002, conducted by Institute for Research, Development and Training of Construction Trades and Management- INSTRUCT, Bangalore" },
      { Id: "79", Content: "National workshop on “Alternative Building Methods (NWABM-2002)” held during 16-18 January 2002 at Indian Institute of Science, Bangalore." },
      { Id: "80", Content: "National Seminar on “ Waves of the Future-Civil Engineering in 21st Century” on 15th-16th June 2001 by Association of Consulting Civil Engineers(India) at Bangalore." },
      { Id: "81", Content: "Work Shop on  “ Advances In Structural Steel-Concrete Composite Construction”, February 26-28,2001, Conducted by Faculty of Civil Engg., Bangalore University and Institute for Steel Development & Growth-INSDAG, Kolkata" },
      { Id: "82", Content: "State Level Work Shop on  “Computer Related Subjects of Civil Engineering’’ 20th & 21st October 2000, Conducted by Malnad College of Engineering, Hassan" },
      { Id: "83", Content: "Faculty Development Program In “Entrepreneurship” 15th to 21st February 1998, Regional Engineering College, Calicut, Kerala" },
      { Id: "84", Content: "Short Term Course On “Structural Design for Dynamic Loads” January 1998, I.I.Sc, Bangalore" },
      { Id: "85", Content: "Short Term Course On “Finite Element Procedures”   25th May to 29th July, 1995 Conducted By ISTE at M.S.R.T. , Bangalore" },
      { Id: "86", Content: "“UDBHAVA- Forum for Urban Design, Built Heritage, Architecture & Visual Arts” Training camp at Tirthahalli on 26th to 28th August 1995 as Resource Person." },
      { Id: "87", Content: "“NINASAM Cultural Workshop” (October 1994, Conducted By Dr. U.R. Anantha Murthy at Heggodu, Sagara, Shimoga" },
      { Id: "88", Content: "UDBHAVA’s Architecture camp with the theme “ Context and Creativity” at Melukote during 26,27, & 28th August 1994" },
      { Id: "89", Content: "Work Shop On ‘Geotechnical Investigations for Buildings’ 23rd -24th May 1994, Conducted By  ‘Indian Geotechnical Society’- Karnataka Centre at R.V.C.E., Bangalore" },
    ]

    return (
      <div>
        {/* Header */}
        <Header />

        {/* Banner */}
        <Banner image="banner/banner04.jpg" />

        <div className="container section-title with-desc clearfix paddingtop-2">
          <div className="title-header"><h4 className="title">Information about Research Project Works [PhD/M.Sc. (Engg.)] Guidance:</h4></div>
          <table class="table paddingtop-2">
            <tbody>
              <tr>
                <th>Sl. No.</th>
                <th>Name of the Candidate</th>
                <th>Registered under VTU</th>
                <th>Area of  Specialization</th>
              </tr>
              {
                ResearchProject.map((item, i) => (
                  <tr>
                    <td>{item.Id}</td>
                    <td>{item.Content}</td>
                    <td>{item.RegisteredUnder}</td>
                    <td>{item.Specialization}</td>
                  </tr>
                ))
              }
            </tbody>
          </table>
          <div className="title-header paddingtop-2"><h4 className="title">M.Tech (Structural Engineering)-Project Works Guided:</h4></div>
          <ul className="ttm-list ttm-list-style-icon">
            <div className="row">
              {
                ProjectWorksGuided.map((item, i) => (
                  <div className="col-lg-6">
                    <li><i className="fa fa-check-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content">{item.Content}</span></li>
                  </div>
                ))
              }
            </div>
          </ul>
        </div>

        <div className="container section-title with-desc clearfix"><br />
          <div className="title-header"><h4 className="title">Technical Talks / Seminars /Courses / Workshops Organised*</h4></div>
          <ul className="ttm-list ttm-list-style-icon">
            {
              TechTalks.map((item, i) => (
                <li><i className="fa fa-check-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content">{item.Content}</span></li>
              ))
            }
          </ul>
        </div>

        <div className="container section-title with-desc clearfix"><br />
          <div className="title-header"><h4 className="title">Workshops</h4></div>
          <ul className="ttm-list ttm-list-style-icon">
            {
              Workshops.map((item, i) => (
                <li><i className="fa fa-check-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content">{item.Content}</span></li>
              ))
            }
          </ul>
        </div>

        <br/><br/>
        
        {/* Footer */}
        <Footer />

      </div>
    )
  }
}
