import React, { Component } from 'react';
import Header from '../../components/common/Header';
import Banner from '../../components/common/Banner';
import Footer from '../../components/common/Footer';

export default class Experience extends Component {

    render() {

        const TeachingExperience = [
            { Id: "1", Date: "2018 to Present", Content: "Principle/Director Bangalore Institute of Technology, Bangalore" },
            { Id: "2", Date: "2004 To Present", Content: "Professor in Civil Engineering Bangalore Institute of Technology, Bangalore" },
            { Id: "3", Date: "2000 To 2004", Content: "Assistant Professor in Civil Engineering. Bangalore Institute of Technology, Bangalore" },
            { Id: "4", Date: "1998 To 2000", Content: "Senior Grade Lecturer in Civil Engineering. Bangalore Institute of Technology, Bangalore" },
            { Id: "5", Date: "1991 To 1998", Content: "Lecturer in Civil Engineering Bangalore Institute of Technology, Bangalore" },
            { Id: "6", Date: "1990 To 1991", Content: "Part time Lecturer in Civil Engineering Bangalore Institute of Technology, Bangalore" }
        ]

        const Service = [
            { Id: "1", Date: "1st June 2018 to Present", Content: "Principal, Bangalore Institute of Technology, Professor, Department of Civil Engineering" },
            { Id: "2", Date: "12th Nov 2014 to 31-05-2018", Content: "Upgrading all the facilities at Department and at the college to the latest requirements" },
            { Id: "3", Date: "From Dec 1994 to Nov 2014 ", Content: "Responsibilities include, Maintaining a database of students for all engineering branches, database of Industries, coordinating with the companies for campus interviews, organizing training Program for communication skills, aptitude tests, Personality development etc,." },
            { Id: "4", Date: "1994-2002", Content: "In the year 1997, conducted a National Level Technical Festival-“MRIGATRISHNA”, first of its kind. 36 Technical Institutions and 24 Industries participated" },
            { Id: "5", Date: "From 2007 to 2009", Content: "Secretary, “Association of Consulting Civil Engineers” Bangalore Centre, Association of Consulting Civil Engineers (India), Bangalore Centre, is actively involved in bringing about awareness among the Engineering fraternity about the latest trends and advancements in Construction Technology and Science. It has been regularly conducting programs under the banner of Technical Evenings, Endowment Lectures, Study Circles, Placement Orientation Programme, National Seminars, Workshops, awareness programmes etc. to disseminate the knowledge about various projects that are being planned and executed." },
            { Id: "6", Date: "2009-2011", Content: "Secretary General, “Association of Consulting Civil Engineers (India)”" },
            { Id: "7", Date: "1994-2002", Content: "Secretary/Treasurer, BIT Chapter of ‘Indian Society For Technical Education’., Responsibilities include, organizing technical seminars, workshops for students and staff members. Enrolling staff members to the society. (Have a record of enrolling 76 staff members at a time)" },
            { Id: "8", Date: "1994-98", Content: "Editorial Board for ‘BITANNICA’ The BIT annual Magazine" },
            { Id: "9", Date: "1998", Content: "Chief Editor For ‘BITANNICA’ The BIT annual Magazine" },
            { Id: "10", Date: "1998-2003", Content: "Chief Editor For ‘DRISTHI’ The BIT News Letter" },
            { Id: "11", Date: "1997-2006", Content: "Manager of the Institute web site; www.bit-bangalore.org" },
            { Id: "12", Date: "1997-2006", Content: "Committee member for Industry Institute Interaction" },
            { Id: "13", Date: "1990-Present", Content: "Convener & Founder For  ‘R.E.A.D.’ (Rural Education And Development Which Assist Rural Children for their Education)" },
            { Id: "14", Date: "1994-2004", Content: "Resource Person For ‘UDBHAVA’ (The Forum For Urban Design, Built Heritage, Architecture & Visual Arts), Which Organizes Seminars, Workshops Etc., For Students of Architecture & Engineering)" },
            { Id: "15", Date: "From 2011", Content: "Chief Editor For ‘Bulletin of ACCE(I)’, Association of Consulting Civil Engineers(India)" },
        ]

        return (
            <div>
                {/* Header */}
                <Header />

                {/* Banner */}
                <Banner image="banner/banner01.jpg" />

                <div className="container section-title with-desc clearfix"><br />
                    <div className="title-header"><h4 className="title">Teaching Experience</h4></div>
                    <ul className="ttm-list ttm-list-style-icon">
                        {
                            TeachingExperience.map((item, i) => (
                                <li><i className="fa fa-check-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content">{item.Content} - <b>({item.Date})</b></span></li>
                            ))
                        }
                    </ul>
                    <br />
                </div>

                <div className="container section-title with-desc clearfix paddingtop-2">
                    <div className="title-header"><h4 className="title">Service</h4></div>
                    <ul className="ttm-list ttm-list-style-icon">
                        <table class="table">
                            <tbody>
                                <tr>
                                    <th style={{ width: "5%" }}>Sl</th>
                                    <th style={{ width: "20%" }}>Date</th>
                                    <th style={{ width: "75%" }}>Details</th>
                                </tr>
                                {
                                    Service.map((item, i) => (
                                        <tr>
                                            <td>{item.Id}</td>
                                            <td>{item.Date}</td>
                                            <td>{item.Content}</td>
                                        </tr>
                                    ))
                                }
                            </tbody>
                        </table>
                    </ul>
                </div>

                {/* Footer */}
                <Footer />
            </div>
        )
    }
}
