import React, { Component } from "react";
import Header from '../../components/common/Header';
import Banner from '../../components/common/Banner';
import Footer from '../../components/common/Footer';

const GalleryItems = [
    { Id: "1", Image: "amu-1.jpg" },
    { Id: "2", Image: "amu-2.jpg" },
    { Id: "3", Image: "amu-3.jpg" },
    { Id: "4", Image: "amu-4.jpg" },
    { Id: "5", Image: "amu-5.jpg" },
    { Id: "6", Image: "amu-6.jpg" },
    { Id: "7", Image: "amu-7.jpg" },
    { Id: "8", Image: "amu-8.jpg" },
    { Id: "9", Image: "amu-9.jpg" },
    { Id: "10", Image: "amu-10.jpg" },
    { Id: "11", Image: "amu-11.jpg" },
    { Id: "12", Image: "amu-12.jpg" },
    { Id: "13", Image: "amu-13.jpg" },
    { Id: "14", Image: "amu-14.jpg" },
    { Id: "15", Image: "amu-15.jpg" },
    { Id: "16", Image: "amu-16.jpg" },
    { Id: "17", Image: "amu-17.jpg" },
    { Id: "18", Image: "amu-18.jpg" },
    { Id: "20", Image: "amu-19.jpg" },
    { Id: "20", Image: "amu-20.jpg" },
    { Id: "21", Image: "amu-21.jpg" },
    { Id: "22", Image: "amu-22.jpg" },
    { Id: "23", Image: "amu-23.jpg" },
    { Id: "24", Image: "amu-24.jpg" },
    { Id: "25", Image: "amu-25.jpg" },
    { Id: "26", Image: "amu-26.jpg" },
    { Id: "27", Image: "amu-27.jpg" },
    { Id: "28", Image: "amu-28.jpg" },
    { Id: "29", Image: "amu-29.jpg" },
    { Id: "30", Image: "amu-30.jpg" },
    { Id: "31", Image: "amu-31.jpg" },
]

export default class Gallery extends Component {

    render() {
        return (
            <div>
                {/* Header */}
                <Header />

                {/* Banner */}
                <Banner image="banner/banner01.jpg" />

                <div className="container section-title with-desc clearfix"><br/><br/>
                    <div className="title-header"><h4 className="title">Gallery</h4></div>
                    <div className="row">
                        {
                            GalleryItems.map((item, i) => (
                                <div className="col-lg-4">
                                    <img className="img-fluid" src={process.env.PUBLIC_URL + "/assets/images/gallery/" + item.Image} alt="Gallery Image" /><br/><br/>
                                </div>
                            ))
                        }
                    </div>
                    <br/><br/>
                </div>

                {/* Footer */}
                <Footer />
            </div>
        )
    }
}

