import React, { Component } from 'react';
import Header from '../../components/common/Header';
import Banner from '../../components/common/Banner';
import Footer from '../../components/common/Footer';

export default class Associations extends Component {

  render() {

    const ProfessionalAssociations = [
      { Id: "1", Header: "LM-20814;29-8-1995", Content: "Life Member-Indian Society for Technical Education" },
      { Id: "2", Header: "8462;2007", Content: "Life Member –Indian Concrete Institute" },
      { Id: "3", Header: "LM.06.021;2006", Content: "Life Member –American Concrete Institute (India Chapter)" },
      { Id: "4", Header: "F-1348;30-06-2007", Content: "Fellow Member –Association for Consulting Civil Engineers (India) Life Member from May 2002-30-06-2008  1348-L)" },
      { Id: "5", Header: "LM-214;7-9-2007", Content: "Life Member-INSTRUCT" },
      { Id: "6", Header: "2007", Content: "Life Member- ACHMM (India Chapter)" },
      { Id: "7", Header: "IINDVM-165", Content: "Member of Institute for Steel Development & Growth (INSDAG)" },
      { Id: "8", Header: "F-1139270", Content: "Fellow Member-Institution of Engineers(I)" },
      { Id: "9", Header: "LF-1699 2-07-2008", Content: "Fellow Member-Indian Institution of Bridge Engineers" },
      { Id: "10", Header: "LM 14940", Content: "Life Member-Kannada Sahithya Parishath" },
      { Id: "11", Header: "B97/Inst/97/2012/69661", Content: "Life Member- Indian Society for Training & Development" },
    ]

    const MemberReviewer = [
      { Id: "1", Content: "ICI Journal-Indian Concrete Institute" },
      { Id: "2", Content: "Bulletin of Association of Consulting Civil Engineers(India)" },
      { Id: "3", Content: "Built Expressions" },
      { Id: "4", Content: "SME WORLD, New Delhi" },
      { Id: "5", Content: "BITANNICA" },
      { Id: "6", Content: "International Journal of Emerging Technologies and Application in Engineering, Technology and Sciences" }
    ]


    return (
      <div>
        {/* Header */}
        <Header />

        {/* Banner */}
        <Banner image="banner/banner01.jpg" />

        <div className="container section-title with-desc clearfix"><br />
        <div className="title-header"><h4 className="title">Associations</h4></div>
          <table class="table">
            <tbody>
              {
                ProfessionalAssociations.map((item, i) => (
                  <tr>
                    <th>{item.Header}</th>
                    <td>{item.Content}</td>
                  </tr>
                ))
              }
            </tbody>
          </table>
        </div>

        <div className="container section-title with-desc clearfix"><br />
          <div className="title-header paddingtop-2"><h4 className="title">Member / Reviewer Editorial Board</h4></div>
          <div className="row">
            <div className="col-lg-4">
              <img className="img-fluid" src={process.env.PUBLIC_URL + "/assets/images/home/Home-2.jpg"} alt="single-img-eighteen" />
            </div>
            <div className="col-lg-8" style={{ margin: "auto" }}>
              <ul className="ttm-list ttm-list-style-icon">
                {
                  MemberReviewer.map((item, i) => (
                    <li><i className="fa fa-check-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content">{item.Content}</span></li>
                  ))
                }
              </ul>
            </div>
          </div>
        </div>

        {/* Footer */}
        <Footer />
      </div>
    )
  }
}
