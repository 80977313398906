import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Container, Row, Col } from 'react-bootstrap';
import { Styles } from "./styles/header.js";

export default class Header extends Component {
    render() {
        return (
            <div>
                <Styles>
                    <section className="top-bar">
                        <Container>
                            <Row>
                                <Col lg="5" md="6">
                                    <div className="logo">
                                        <Link to={process.env.PUBLIC_URL + "/"}>
                                            <img src={process.env.PUBLIC_URL + "/assets/images/general/logo.png"} style={{width:"32.5rem",marginTop:"1rem"}}/>
                                        </Link>
                                    </div>
                                </Col>
                                <Col lg="6" md="6">
                                    <div className="menu-box bar-right d-flex justify-content-center">
                                        <ul className="nav menu-nav">
                                            <li className="nav-item active"><Link className="nav-link" to={process.env.PUBLIC_URL + "/"}>Home</Link></li>
                                            <li className="nav-item active"><a className="nav-link" href="https://bit-bangalore.edu.in" target="_blank">BIT</a></li>
                                            <li className="nav-item active"><Link className="nav-link" to={process.env.PUBLIC_URL + "/gallery"}>Gallery</Link></li>

                                            <li className="nav-item"><div className="nav-link" style={{ color: "white" }}>|</div></li>
                                            
                                            <li className="nav-item"><a className="nav-link" href="https://www.facebook.com/aswathmu" target="_blank"><i className="fab fa-facebook-f"></i></a></li>
                                            <li className="nav-item"><a className="nav-link" href="https://www.linkedin.com/in/aswathmu" target="_blank"><i className="fab fa-linkedin"></i></a></li>
                                            <li className="nav-item"><a className="nav-link" href="https://www.instagram.com/aswathmu" target="_blank"><i className="fab fa-instagram"></i></a></li>
                                            <li className="nav-item"><a className="nav-link" href="https://www.youtube.com/bitsince1979" target="_blank"><i className="fab fa-youtube"></i></a></li>

                                            <li className="nav-item"><div className="nav-link" style={{ color: "white" }}>|</div></li>
                                            <li className="nav-item"><div className="nav-link" style={{ color: "white" }}>080-26615865</div></li>
                                        </ul>
                                    </div>
                                </Col>
                            </Row>
                        </Container>
                    </section>
                    {/* Logo Area */}
                    <section className="header-area">
                        <Container>
                            <div class="row">
                                <div class="offset-3 col-9">
                                    <div className="menu-box d-flex justify-content-end">
                                        <ul className="nav menu-nav">
                                            <li className="nav-item active"><Link className="nav-link" to={process.env.PUBLIC_URL + "/experience"}>Experience</Link></li>
                                            <li className="nav-item active"><Link className="nav-link" to={process.env.PUBLIC_URL + "/publications"}>Publications</Link></li>
                                            <li className="nav-item active"><Link className="nav-link" to={process.env.PUBLIC_URL + "/workshops"}>Guideship</Link></li>
                                            <li className="nav-item active"><Link className="nav-link" to={process.env.PUBLIC_URL + "/initiatives"}>Initiatives</Link></li>
                                            <li className="nav-item active"><Link className="nav-link" to={process.env.PUBLIC_URL + "/associations"}>Associations</Link></li>
                                            <li className="nav-item active"><Link className="nav-link" to={process.env.PUBLIC_URL + "/recognition"}>Recognition</Link></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </Container>
                    </section>
                </Styles>
                {/* Mobile Menu
                <MobileMenu /> */}
            </div>
        )
    }
}