import React, { Component } from 'react';
import Header from '../../components/common/Header';
import Banner from '../../components/common/Banner';
import Footer from '../../components/common/Footer';

export default class Recognition extends Component {

  render() {

    const Award = [
      { Id: "1", Content: "President, Association of Consulting Civil Engineers(India) 2019-2021" },
      { Id: "2", Content: "Felicitation by the Chief Minister Shri. Jagadeesh Shettar for editing the Manual; Editor-in-Chief for “Reference Manual for Field Engineers on Building Construction” published by Task Force for Quality Assurance in Public Constructions, Government of Karnataka." },
      { Id: "3", Content: "Achievement Award for Outstanding Academician, CIDC Vishwakarma Award 2014,  The National Award is conferred on 7th March 2014 by Construction Industry Development Council (CIDC) established by Planning Commission, Government of India and the Construction Industry" },
      { Id: "4", Content: "Eminent Engineer Award by ACCE(I) Bangalore Centre, Felicitation during the Engineers Day Celebration, September 2011" },
      { Id: "5", Content: "Vocational Excellence Award “Academician Par Excellence” by Rotary RBSE, 22-7-2017" },
      { Id: "6", Content: "Academic Senate Member, Visvesvaraya Technological University, July 2016 – June 2019" },
      { Id: "7", Content: "Chairman-Board of Studies-Civil/TR/CT at Visvesvaraya Technological University, July 2016- June 2019" },
      { Id: "8", Content: "“Dr. B K Ramaiah Award for Research & Industry Institute Culture” by AMC Engineering College, March 2017" },
      { Id: "9", Content: "Vice President, Indian Concrete Institute " },
      { Id: "10", Content: "Chairman Indian Concrete Institute’s Technical committee on Alternatives To River Sand ICI-TC/07" },
      { Id: "11", Content: "Session Chairman, International Conference on “Innovations in Concrete for Infrastructure Challenges (INFRACON-2017)”, 6-7, October 2017, Nagpur, India organised by Indian Concrete Institute" },
      { Id: "12", Content: "Assistant Governor, July 2015-June 2016, Rotary International District 3190" },
      { Id: "13", Content: "Member; Forum on Technology Options for Decentralised Waste Water Management,  Rajiv Gandhi Rural Housing Corporation Limited, Government of Karnataka" },
      { Id: "14", Content: "FKCCI Committee Member-Human Resources and Skill Development’2013-14, 2014-2015" },
      { Id: "15", Content: "National Seminar Session Chairman: 26th & 27th April 2013, National Seminar on “Steel Structures-STEELCON”, S J College of Engineering, Mysore and ACCE(I)" },
      { Id: "16", Content: "National Conference Session Chairman: 1st March 2013, National Conference On “Project Management in Construction Sector”, NIMHANS Convention Centre, organised by INSTRUCT." },
      { Id: "17", Content: "International Conference Session Chairman: Structural Engineers World Congress 2007, the third Congress, Parallel session series 1 on November 3rd 2007, Nov 2 – 7, 2007 at Hotel Grand Ashok, Bangalore." },
      { Id: "18", Content: "Organizing Committee Member: ‘Improving Performance of Concrete Structures’, The Fourth International ﬁb Congress 2014, Mumbai ,February 10 - February 14, 2014, Organised by: IMC-ﬁ b of The Institution of Engineers (India)" },
      { Id: "19", Content: "Former Chairman, Indian Concrete Institute, Bengaluru Centre" },
      { Id: "20", Content: "Former Consultant Editor, Built Expressions" },
      { Id: "21", Content: "Bangalore Bureau Head, SME WORLD, New Delhi" },
      { Id: "22", Content: "Best Rotarian- Rotary Bangalore South East" },
      { Id: "23", Content: "Member BOE,VTU, 2010" },
      { Id: "24", Content: "SECRETARY, Rotary Bangalore South East, July 2010 – June 2011 " },
      { Id: "25", Content: "President ,  Rotary Bangalore South East, July 2012 – June 2013" },
      { Id: "26", Content: "Vice Chairman, Career Counseling &Development, Rotary International District -3190 (2013-14)" },
      { Id: "27", Content: "Coordinator,  VTT Committee, Rotary International District -3190 (2013-14)" },
      { Id: "28", Content: "Member, Resource Committee, Rotary International District -3190 (2013-14)" },
      { Id: "29", Content: "Vice Chairman,  Speakers' Bank Committee, Rotary International District -3190 (2014-15)" },
      { Id: "30", Content: "Vice Chairman, TV Coordination Committee, Rotary International District -3190 (2014-15)" },
      { Id: "31", Content: "Member, Press Coordination Committee, Rotary International District -3190 (2014-15)" },
      { Id: "32", Content: "Former Secretary General of Association of Consulting Engineers(India)" },
      { Id: "33", Content: "Former Secretary of Association of Consulting Engineers(India), Bangalore Centre" },
      { Id: "34", Content: "Former Editor-in-Chief: Bulletin of ACCE(I), and Chairman-Bulletin Committee, Association of Consulting Civil Engineers(India)" },
      { Id: "34", Content: "Former Vice President BIT Alumni Association" }
    ];

    return (
      <div>
        {/* Header */}
        <Header />

        {/* Banner */}
        <Banner image="banner/banner01.jpg" />

        <div className="container section-title with-desc clearfix"><br />
          <div className="title-header"><h4 className="title">Award / Recognition</h4></div>
          <ul className="ttm-list ttm-list-style-icon">
            {
              Award.map((item, i) => (
                <li><i className="fa fa-check-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content">{item.Content}</span></li>
              ))
            }
          </ul>

          <div className="title-header paddingtop-2"><h4 className="title">TV & News</h4></div>
          <ul className="ttm-list ttm-list-style-icon">
            <li><i className="fa fa-check-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content">T.V. Shows on buildings, Telecast in Udaya T.V., E-T.V, Samaya, TV 9 etc.</span></li>
            <li><i className="fa fa-check-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content">Articles on buildings and structures, p, A+D, I A & B, Times of India, New Indian Express, Vijay Times, Bangalore Mirror, Deccan Herald, The Hindu</span></li>
          </ul>
        </div>

        {/* Footer */}
        <Footer />
      </div>
    )
  }
}
