import React, { Component } from 'react';
import Banner from '../../components/common/Banner';
import Swiper from 'react-id-swiper';

export default class PrincipalInfo extends Component {
    render() {

        const params = {
            autoplay: {
                delay: 3000,
                disableOnInteraction: false,
            },
            slidesPerView: 1,
            spaceBetween: 10,
            loop: true,
        };

        const data = [
            { Id: "1", Image: "Lander-1.jpg" },
            { Id: "2", Image: "Lander-2.jpg" },
            { Id: "3", Image: "Lander-3.jpg" },
            { Id: "4", Image: "Lander-4.jpg" },
            { Id: "5", Image: "Lander-5.jpg" },
            { Id: "6", Image: "Lander-6.jpg" },
        ];

        const symmary = [
            { Id: "1", Content: "Principal/Director, Bangalore Institute of Technology, Bengaluru-560004" },
            { Id: "2", Content: "Ph.D. in Civil Engineering from Bangalore University specialized in concrete structures, Master’s Degree in Structural Engineering, Bachelor Degree in Civil Engineering." },
            { Id: "3", Content: "Professor and Head Civil Engineering at Bangalore Institute of Technology, Bangalore; With BIT from1991 as faculty" },
            { Id: "4", Content: "Memberships with the ISTE-Indian Society for Technical Education , ACCE(I)-Association for Consulting Civil Engineers (India), ACI-American Concrete Institute (India Chapter), ICI-Indian Concrete Institute INSTRUCT , ACHMM (India Chapter), INSDAG, IIBE-Indian Institution of Bridge Engineers, IEI-Institution of Engineers(India)" },
            { Id: "5", Content: "Resource Person for UDBHAVA: a forum for urban design, built heritage, architecture and visual arts." },
            { Id: "6", Content: "Training & Placement officer- BIT, Responsible for starting the Centre during 1994 and continued to carry out the activities till November 2014. Placed more than 23,000 students in various national and multinational companies." },
            { Id: "7", Content: "PG & Research, Responsible for starting the PG course M.Tech in Structural Engineering and Research Centre in Civil Engineering" },
            { Id: "8", Content: "Web master- bit-bangalore.org, responsible for creating web site for BIT" },
            { Id: "9", Content: "Initiated various extracurricular (cultural & Technical) activities in the college for the overall development of the Institute from 1994 onwards." },
            { Id: "10", Content: "Published more than 100 papers at national and international journals/conferences" },
            { Id: "11", Content: "Guided three students for their PhD and presently guiding 5 students for their PhD" },
            { Id: "12", Content: "Guided more than 40 students for their M.Tech" },
            { Id: "13", Content: "Organinised more than 300 workshops seminars for professionals/faculty/students for knowledge dissemination" },
            { Id: "14", Content: "A Freemason" }
        ]

        const skills = [
            { Id: "1", Content: "Teaching ,Training & Development" },
            { Id: "2", Content: "Civil Engineering" },
            { Id: "3", Content: "Structural Engineering" },
            { Id: "4", Content: "Concrete Technology" },
            { Id: "5", Content: "Analysis, Design and Construction" },
            { Id: "6", Content: "Research & Development" },
            { Id: "7", Content: "Educational Consulting & Leadership" },
            { Id: "8", Content: "Education Management" },
            { Id: "9", Content: "Campus Management" },
            { Id: "10", Content: "Technical Recruiting" },
            { Id: "11", Content: "Employee Relations" },
            { Id: "12", Content: "Curriculum Design" },
            { Id: "13", Content: "Administration" },
            { Id: "14", Content: "Classroom Instruction" },
            { Id: "15", Content: "Public Relations" },
            { Id: "16", Content: "Professional Body Activities" }
        ]

        const fieldofinterest = [
            { Id: "1", Content: "Reinforced Concrete and Steel Structures" },
            { Id: "2", Content: "Evaluation and Restoration of Structures" },
            { Id: "3", Content: "Quality Control and Good Construction Practices" },
            { Id: "4", Content: "Cost Effective Construction Technology and Implementation" },
            { Id: "5", Content: "Alternative Building Methods and Materials" },
            { Id: "6", Content: "Human Resource Development" },
            { Id: "7", Content: "Rural Education and Development" },
            { Id: "8", Content: "Training and placements" },
        ]

        return (
            <div>
                {/* Banner */}
                <Banner image="banner/banner02.jpg" />

                {/* About Section */}
                <section className="ttm-row aboutus-section clearfix">
                    <div className="container-xl">
                        <div className="row">
                            <div className="col-lg-6 res-991-mt-40 res-991-text-center" style={{ margin: "auto" }}>
                                <div className="position-relative res-991-mt-30">
                                    <div className="ttm_single_image-wrapper">
                                        <Swiper getSwiper={null} className="trancarousel" {...params}>
                                            {data.map((item, i) => (
                                                <div key={i} className="single_post widgets_small post_type5">
                                                    <img className="img-fluid" src={process.env.PUBLIC_URL + "/assets/images/home/" + item.Image} alt="single-img-eighteen" />
                                                </div>
                                            ))}
                                        </Swiper>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="section-title with-desc clearfix">
                                    <div className="title-header">
                                        <h2 className="title">Dr. Aswath M U</h2>
                                        <h5>Principal & Director, Bangalore Institute of Technology</h5>
                                    </div>
                                    <table class="table">
                                        <tbody>
                                            <tr>
                                                <th>Qualification</th>
                                                <td>Ph.D. in Civil Engineering from Bangalore University specialized in concrete structures, Master’s Degree in Structural Engineering, Bachelor Degree in Civil Engineering.</td>
                                            </tr>
                                            <tr>
                                                <th>VTU Positions</th>
                                                <td>Academic Senate Member, VTU, July 2016 – June 2019, Chairman - BOS Civil/TR/CT at VTU, July 2016- June 2019</td>
                                            </tr>
                                            <tr>
                                                <th>Professional Associations</th>
                                                <td>
                                                    <b>President</b>, Association of Consulting Civil Engineers(India) 2019-2021<br />
                                                    <b>Vice President</b>, Indian Concrete Institute
                                                </td>
                                            </tr>
                                            <tr>
                                                <th>Recognition</th>
                                                <td>"Achievement Award for <b>Outstanding Academician</b>" , CIDC Vishwakarma Award 2014</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <div className="container section-title with-desc clearfix">
                    <div className="title-header"><h4 className="title">Profile Summary</h4></div>
                    <ul className="ttm-list ttm-list-style-icon">
                        <div className="row">
                            {
                                symmary.map((item, i) => (
                                    <div className="col-lg-6">
                                        <li><i className="fa fa-check-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content">{item.Content}</span></li>
                                    </div>
                                ))
                            }
                        </div>
                    </ul>
                </div>

                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="container section-title with-desc clearfix paddingtop-2">
                                <div className="title-header"><h4 className="title">Skills</h4></div>
                                <ul className="ttm-list ttm-list-style-icon">
                                    <div className="row">
                                        {
                                            skills.map((item, i) => (
                                                <div className="col-lg-3">
                                                    <li><i className="fa fa-check-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content">{item.Content}</span></li>
                                                </div>
                                            ))
                                        }
                                    </div>
                                </ul>
                            </div>
                        </div>

                        <div className="col-lg-12">
                            <div className="container section-title with-desc clearfix paddingtop-2">
                                <div className="title-header"><h4 className="title">Field of Interest </h4></div>
                                <ul className="ttm-list ttm-list-style-icon">
                                    <div className="row">
                                        {
                                            fieldofinterest.map((item, i) => (
                                                <div className="col-lg-3">
                                                    <li><i className="fa fa-check-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content">{item.Content}</span></li>
                                                </div>
                                            ))
                                        }
                                    </div>
                                </ul>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        )
    }
}
