import React, { Component } from 'react';
import Header from '../../components/common/Header';
import Banner from '../../components/common/Banner';
import Footer from '../../components/common/Footer';

export default class Publications extends Component {

  render() {

    const BooksPublished = [
      { Id: "1", Header: "Books", Content: "Opportunities limitless" },
      { Id: "2", Header: "Books / Proceedings Edited", Content: "Books/Proceedings Edited", Content: "Civil Engineering in India- a proud journey in the past quarter century: ACCE(I) Compendium" },
      { Id: "3", Header: "Books / Proceedings Edited", Content: "Reference Manual for Field Engineers on Building  Construction : Task Force for Quality Assurance in Public Constructions- Government of Karnataka(Editor-in-Chief)" },
      { Id: "4", Header: "Books / Proceedings Edited", Content: "Alternatives to River Sand-a sustainable approach: ICI-KBC(Editor-in-Chief)" },
      { Id: "5", Header: "Books / Proceedings Edited", Content: "Quality Assurance and Innovations in Ready Mixed Concrete(Editor-in-Chief)" },
      { Id: "6", Header: "Books / Proceedings Edited", Content: "Editor for conference proceedings of ACCE(I), ICI" },
    ]

    const InternationalJournals = [
      { Id: "1", Content: "D. P. Archana, H. N. Jagannatha Reddy, R. Prabhakara, M. U. Aswath, A. Chandrashekar, “Processing and Properties of Biodegradable Composites to Strengthen Structures”, Journal of The Institution of Engineers (India): Series C volume 103, pages 39–52 (2022)" },
      { Id: "2", Content: "Smita Singh, M.U. Aswath, R.V. Ranganath, “Performance assessment of bricks and prisms: Red mud based geopolymer composite”, Journal of Building Engineering,  Elsevier Ltd. Volume 32, November 2020." },
      { Id: "3", Content: "Smita Singh, M.U. Aswath, R.V. Ranganath, “Effect of mechanical activation of red mud on the strength of geopolymer binder”, Construction and Building Materials, Elsevier Ltd., Volume 177, 20 July 2018, Pages 91-101 " },
      { Id: "4", Content: "Smita S, Aswath M. U, Ranganath R. V, “Durability of Red mud Based Geopolymer Paste in Acid      Solutions”, Material Science Forum, ISSN: 1662-9752, Vol. 166, pp 99-105, August 2016, Scopus Indexed." },
      { Id: "5", Content: "Smita S, Basavanagowda S. N, Aswath M. U, Ranganath R. V, “Durability of Bricks Coated with Red mud Based Geopolymer Paste”, Materials Science and Engineering, vol 149 (2016), Scopus Indexed." },
      { Id: "6", Content: "Smita S, Biswas R. D, Aswath M. U, “Experimental Study on Redmud based Geopolymer Concrete with Fly ash & GGBS in Ambient Temperature Curing”, International Journal of Advances in Mechanical and Civil Engineering, ISSN: 2394-2827,  Special Issue, Sep.-2016." },
      { Id: "7", Content: "Smita S, Mahesh H. P, Aswath M. U, Ranganath R. V, “Flexure and Shear Bond Strength of Red Mud – Fly Ash Based Geopolymer Mortars”, IJRET: International Journal of Research in Engineering and Technology eISSN: 2319-1163 | pISSN: 2321-7308, special issue, Nov 2016." },
      { Id: "8", Content: "Smita S, Aswath M. U, Ranganath R. V, “Effect of Curing Methods on the Property of Red Mud Based Geopolymer Mortar”, International Journal of Civil Engineering and Technology (IJCIET), Vol. 8, Issue 10, October 2017, pp. 1481–1489. Scopus Indexed." },
      { Id: "9", Content: "Majunath L R, Sandya Anvekar and Aswath M U, “New Green Initiatives in the waste byproducts of steel industry as alternative green materials for concrete construction in India”, 4th Asian Conference on Ecstasy in Concrete,ICI-ACECON 2015, 8-10 October 2015, Kolkota, pp289-298" },
      { Id: "10", Content: "Ashwin Thammaiah K, Raghunath S and Aswath M U, “Influence of Geometric Parameters on the In-Plane Response of Masonry Walls – Analytical Studies”, 4th International Engineering Symposium - IES 2015, March 4-6, 2015, Kumamoto University, Japan, pp C8-9-1to C8-9-7" },
      { Id: "11", Content: "Ashwin Thammaiah K, Pratyusha M Naik, Aswath M U & Raghunath S, “Dynamic Tests to Evaluate in-Plane Performance of Holow Concrete Block Masonry”, Journal of Structural Engineers World Congress(SEWC), February 2015, pp35-45" },
      { Id: "12", Content: "Smita Singh, Aswath M U and R V Ranganath, Cover Feature-“Geopolymer Concrete” Built Expressions, Vol: 3, Issue:6, June 2014,  pp 60-64, ISSN:2277-324X(http://www.builtexpressions.in/OnlineMagazine/Bangalore/Pages/Geopolymer-Concrete-876.aspx)" },
      { Id: "13", Content: "L R Manjunatha, M U Aswath, Sandhya R Anvekar and Suresh Rao, Grey Matter - Innovative Special Applications, Built Expressions, Vol: 3, Issue:5, May 2014,  pp 82-86, ISSN:2277-324X" },
      { Id: "14", Content: "Putte Gowda B.S, Aswath M.U, Muthu K.U, Udaya B.N, “Application of ANN for Ultimate Shear Strength of Fly Ash Concrete Beams”, IJRET: International Journal of Research in Engineering and Technology, Volume-02 Issue-13, IC-RICE Conference Issue | Nov-2013,PP 73-78 eISSN: 2319-1163 | pISSN: 2321-73-78" },
      { Id: "15", Content: "L R Manjunatha, M U Aswath, Sandhya R Anvekar and Suresh Rao, “Innovative Special Applications Using RMC: Some Case Studies”, ICI-IWC 2013 International Conference on Innovations in Concrete for Meeting Infrastructure Challenge, organized by the Indian Concrete Institute at Hyderabad, India,  23-26 October 2013, ISBN 978 81 7371 912 7, Published by Universities Press (India) Private Limited, PP 299-306" },
      { Id: "16", Content: "Prashanth N, Sayeed Sulaiman and Aswath M U, “To Study The Effect Of Seismic And Wind Loads On Hyperbolic Cooling Tower Of Varying Dimension And RCC Shell Thickness”, The International Journal Of Science & Technoledge (ISSN 2321 – 919X), Vol 1 Issue 3, September, 2013, pp1-9" },
      { Id: "17", Content: "Aswath M U, “Sand Matter- An Overview of Sand Scenario: The Sand Saga... Construction Industry Writhing in Pain”, Built Expressions, Vol.2, Issue 9, September 2013, PP118-125, ISSN:2277-32AX (http://builtexpressions.com/OnlineMagazine/Bangalore/Pages/The-Sand-Saga-483.aspx)" },
      { Id: "18", Content: "Veena N, Sayeed Sulaiman and Aswath M. U “Comparative Study of the Effect of Seismic and Wind Loads on Cooling Tower With A-Frame and H-Frame Column Supports”, The International Journal Of Science & Technoledge (ISSN 2321 – 919X), Vol 1 Issue 2 August, 2013, pp23-32" },
      { Id: "19", Content: "Veena N, Sayeed Sulaiman and Aswath M. U “Comparative Study of the Effect of Seismic and Wind Load on Cooling Tower With A-Frame Column and H-Frame Column Support”, International Journal Of Emerging Trends in Engineering and Development (ISSN 2249-6149), Vol.4 Issue 3,June-July 2013, pp236-247" },
      { Id: "20", Content: "Putte Gowda B.S, Aswath M.U and Muthu K.U “Experimental investigation on Flexure behaviour of fly ash concrete beams”, International Journal of Advanced Scientific and Technical Research (IJAST). Issue 3, Vol.2 (March-April 2013),  pp-184-199, ISSN 2249-9954" },
      { Id: "21", Content: "Putte Gowda B.S, Aswath M.U and Muthu K.U, “Behaviour Of Fly Ash Concrete Slabs Containing Higher Levels Of Fly Ash”, International Journal of Innovative Research & Development, Issue 4, Vol.2, April 2013, PP 206-223, ISSN 2278 – 0211" },
      { Id: "22", Content: "Putte Gowda B.S, Aswath M.U and Muthu K.U, “Experimental Investigation on Shear Behaviour of Fly Ash Concrete Beams”, International Journal of Emerging Trends in Engineering and Development, Issue 3, Vol.2, March 2013, PP 573-586, ISSN 2249-6149" },
      { Id: "23", Content: "Pradeep J, Sanjay R and Dr. Aswath M U, “ Experimental study on flexural behaviour of fly ash and GGBS based  Geopolymer Concrete Beams in Bending”, International Journal of Emerging Trends in Engineering and Development, Issue 2, Vol. 6, September 2012, PP 419-428, ISSN 2249-6149" },
      { Id: "24", Content: "Sanjay R, Dr. Aswath M U and Smita Singh, “An Experimental Study on Flexural Behaviour of Reinforced Geopolymer Concrete Beams with Recycled Aggregates in Bending”, International Journal of Emerging Trends in Engineering and Development, Issue 2, Vol. 6, September 2012, PP 186-199, ISSN 2249-6149" },
      { Id: "25", Content: "Aswath M.U., Manjunath L.R., “Grey Matter-WORLD OF CONCRETE: “Use Ready Mixed Concrete(RMC) from a certified manufacturer for good quality and durability”, Built Expressions, Vol.1, Issue 9, September 2012, PP82-85, ISSN:2277-32AX" },
      { Id: "26", Content: "Abhishek H N and Dr.Aswath M U, “Strength Studies of Red Mud Based Geopolymer Concrete”, International Journal of Emerging Trends in Engineering and Development, Issue 2, Vol. 5, July 2012, PP 10-32, ISSN 2249-6149" },
      { Id: "27", Content: "Divakar.Y, Manjunath. S and Dr. M.U. Aswath, “Experimental Investigation on Behaviour of Concrete with the Use of Granite Fines” International Journal of Advanced Engineering Research and Studies E-ISSN2249–8974- IJAERS/Vol. I/ Issue IV/July-Sept., 2012, pp 84-89" },
      { Id: "28", Content: "Raghavendra Y.B and Aswath M.U, “Experimental Investigation On Concrete Cured With Various Curing Methods-A Comparative Study”, International Journal of Advanced Scientific Research and Technology, Issue 2, Volume 3 (June- 2012) ISSN: 2249-9954" },
      { Id: "29", Content: "Aswath M.U., Raghavendra Y.B., “Grey Matter: Fly Ash Technology-The Long Term Strength and Durability”, Built Expressions, Vol.1, Issue 6, June 2012, PP66-70, ISSN:2277-32AX" },
      { Id: "30", Content: "Aswath M.U. “Architecture and Building Materials for the Betterment of the Society” International Conference on Advances in Architecture and Civil Engineering: AARCV- 2012, 21-23 June 2012, MSRIT, Bangalore. Keynote Address and Session Chairman." },
      { Id: "31", Content: "Muthu K.U, Aswath M.U. and Prabhakara A. “An Experimental Investigation              on Beam Supported Reinforced Concrete Skew Slabs”  Structural Concrete,        Vol. 9, no. 2, June 2008 (International Journal)" },
      { Id: "32", Content: "Prabhakara, K.U.Muthu & M.U. Aswath, “Maximum Crack width of Beam supported skew slabs”, 6th  International Conference on Concrete Technology for Developing Countries - Amman, Jordan, 21-24 October 2002. (International Conference)" },
      { Id: "33", Content: "Desayi.P, Muthu.K.U & M.U.Aswath, “Deflection Control of  Restrained Rectangular R.C. Slabs” International Journal of Structures, (Roorkee); Vol.12, No.2, July-Dec 1992,Paper no.122, PP117-135(International Journal)" },
    ]

    const NationalJournals = [
      { Id: "1", Content: "Aswath M U, Technical Lecture delivered on “Past Present and Future of Civil Engineering” at VAASTU-16-A national level Technical fest at Sri Krishna Institute of Technology, Bangalore on 21-03-2016" },
      { Id: "2", Content: "Aswath M U, “Precast Technology for Buildings”, Paper/poster presentation at 8th KSTA Annual Conference on Science and Technology for Gen Next Urban Space, jointly organised by KSTA Govt. of Karnataka and BIT, 5th & 6th November 2015, BIT, Bengaluru" },
      { Id: "3", Content: "Aswath M U, “Eco-friendly Alternatives to River Sand”, Paper/poster presentation at 8th KSTA Annual Conference on Science and Technology for Gen Next Urban Space, jointly organised by KSTA Govt. of Karnataka and BIT, 5th & 6th November 2015, BIT, Bengaluru" },
      { Id: "4", Content: "Aswath M U, “Industry Institute Interaction for Future Civil Engineers”, Lecture delivered at Sri Venkateshwara College of Engineering on 20-10-2015" },
      { Id: "5", Content: "Aswath M U, Sensitivity, Sustainability, Green Buildings……and the Society?, Lecture delivered at Jain University, Bangalore on 14th October 2015." },
      { Id: "6", Content: "Aswath M U, Emerging Trends on Sustainable Construction, Lecture delivered at Civil Engineering Department, Faculty of Engineering, CHRIST UNIVERSITY, Mysore Road, Bangalore: 560060, on 24 Jul 2015" },
      { Id: "7", Content: "Aswath M U, “Past, Present and Future of Women Engineers in the Construction Industry”,  Panel discussion/ lecture delivered during one day National Conference “Women Engineers conference and Network-WECaN2015”, 25th July 2015, Bengaluru" },
      { Id: "8", Content: "Aswath M U, “Civil Engineers- Are We Sensitive to the Society?”, Lecture delivered during the Inauguration of the Christite Association of Civil Engineers (CACE), Saturday, 20 June 2015, Civil Engineering Department, CHRIST UNIVERSITY, Kengeri Campus, Mysore Road, Bangalore: 560060" },
      { Id: "9", Content: "Aswath M U, “M.Sand and other alternatives to river sand-Technical guidelines”, Lecture delivered to Consultants and members of ICI-KBC during BESTO Mining PAP program organised by ICI-KBC, 18th-June-2015, Bangalore" },
      { Id: "10", Content: "Aswath M U, “RMC field problems & importance of RMC plant certification”, Lecture delivered to Civil Engineers of Reddy Structures Pvt. Ltd., at Mahaveer Ranches, Electronic city, organised by ICI Bangalore, 16th May-2015" },
      { Id: "11", Content: "Aswath M U, “Quality Certified ‘Ready Mixed Concrete’ for Quality Infrastructure and Use of Alternatives to River Sand in RMC” National Conference on “Recent Advancements In Infrastructural Development (RAID)” , March 25-27, 2015, at JSSAT-Bengaluru(Invited Speaker)" },
      { Id: "12", Content: "Aswath M U, “Mix QUALITY to Achieve Efficiency and Excellence in Construction”, Quality Assurance and Innovations in Ready Mixed Concrete, ICI-Bangalore, National Seminar Document, February 2015, pp5-7" },
      { Id: "13", Content: "Aswath M U, “Capability Certifications for Ready Mixed Concrete Manufacturing and the Organisations behind the Standards: RMCMA-QCI-BMTPC” Quality Assurance and Innovations in Ready Mixed Concrete, ICI-Bangalore, National Seminar Document, February 2015, pp19-23" },
      { Id: "14", Content: "L R Manjunatha, Ravishankar M & Aswath M U, “Growth and Development of Ready Mixed Concrete Industry In India”, Quality Assurance and Innovations in Ready Mixed Concrete, ICI-Bangalore, National Seminar Document, February 2015, pp99-106" },
      { Id: "15", Content: "Aswath M U, “Alternatives for River Sand in RMC for Sustainability”, Quality Assurance and Innovations in Ready Mixed Concrete, ICI-Bangalore, National Seminar Document, February 2015, pp147-158" },
      { Id: "16", Content: "Aswath M U, “Ready Mix Concrete Industry in India Need Trained Man Power for Manufacturing Quality Concrete”, Quality Assurance and Innovations in Ready Mixed Concrete, ICI-Bangalore, National Seminar Document, February 2015, pp185-186" },
      { Id: "17", Content: "Aswath M U, “Quality assurance and efficiency in Construction” Editorial, Bulletin of ACCE (I), Vol.13, No.3, November- December 2014, ISSN:2347-8071 " },
      { Id: "18", Content: "Aswath M U, “Dr. C S Viswanatha, THE DOYEN   OF CIVIL ENGINEERING-The Mentor is no more”, Editorial, Bulletin of ACCE (I), Vol.13, No.2, July - October 2014, ISSN:2347-8071" },
      { Id: "19", Content: "Aswath M U, “Technology Options For Decentralised Waste Water Management”, Bulletin of ACCE (I), Vol.13, No.1, April - June 2014, ISSN:2347-8071, pp 8-10" },
      { Id: "20", Content: "Aswath M U , “Chenab Bridge Jammu and Kashmir, India- - World's Highest Railway Bridge”,  Bulletin of ACCE (I), Vol.13, No.1, April - June 2014, ISSN:2347-8071, pp 12-15" },
      { Id: "21", Content: "Aswath M U , “REGULAR INSPECTION AND MAINTENANCE OF FLYOVERS - The Need and Importance”, Bulletin of ACCE (I), Vol.13, No.1, April - June 2014, ISSN:2347-8071, pp 17-18" },
      { Id: "22", Content: "Pratyusha M Naik, Ashwin Thammiah K, Aswath M U and Raghunath S, “Ultimate Moment Carrying Capacity Of Reinforced Hollow Concrete Block Masonry”, Bulletin of ACCE (I), Vol.13, No.1, April - June 2014, ISSN:2347-8071, pp 22-27" },
      { Id: "23", Content: "Aswath M U , “Ready Mix Concrete Industry in India Need Trained Manpower for Manufacturing Quality Concrete”, Bulletin of ACCE (I), Vol.13, No.1, April - June 2014, ISSN:2347-8071, pp 29-30" },
      { Id: "24", Content: "Aswath M U , “Let's Drive Along”, Bulletin of ACCE (I), Vol.13, No.1, April - June 2014, ISSN:2347-8071, pp 32" },
      { Id: "25", Content: "Aswath M U , “CREATING a better TOMORROW”, Editorial, Bulletin of ACCE (I), Vol.13, No.1, April - June 2014, ISSN:2347-8071" },
      { Id: "26", Content: "Aswath M U “From Knowing to Implementing – Towards Sustainable Sanitation Strategies”- Workshop on Technology options for decentralized Wastewater management,  Panel Discussion 5-6-2014, Bangalore organised by Consortium for DEWATS Dissemination (CDD) Society." },
      { Id: "27", Content: "Aswath M U,“To Check Borewell Mishaps, Follow Safety Guidelines”, Bulletin of ACCE (I),Vol.12, No.4, January-March 2014, ISSN:2347-8071, pp 35" },
      { Id: "28", Content: "V.R.Kowshika and Aswath M U, “Understanding Reasons for Cube Strength Variations”, Bulletin of ACCE (I), Vol.12, No.4, January-March 2014, ISSN:2347-8071, pp 14-15, 37" },
      { Id: "29", Content: "Aswath M U, “A World of Opportunities-Need for Professional Mentoring System”, Editorial, Bulletin of ACCE (I), Vol.12, No.4, January-March 2014, ISSN:2347-8071" },
      { Id: "30", Content: "Aswath M U, “Pothole Menace Remains A Problem”, Bulletin of ACCE (I), Vol.12, No.3, October-December 2013, ISSN:2347-8071, pp 7-18" },
      { Id: "31", Content: "Aswath M U, “Cost Effective and Eco Friendly Constructions”, Bulletin of ACCE (I), Vol.12, No.3, October-December 2013, ISSN:2347-8071, pp 19-24" },
      { Id: "32", Content: "Aswath M U, “Need for Quality Certification of Manufactured Sand”, Bulletin of ACCE (I), Vol.12, No.3, October-December 2013,ISSN:2347-8071, pp 30-32" },
      { Id: "33", Content: "Aswath M U, “Net work & engage with the top professionals for accelerating the future of learning”, Editorial, Bulletin of ACCE (I), Vol.12, No.3, October-December 2013, ISSN:2347-8071" },
      { Id: "34", Content: "Aswath M U, “Alternatives To River Sand - A Sustainable Approach”, Editorial, Alternatives To River Sand - A Sustainable Approach ,Seminar Document, ICI-KBC, Dec 2013" },
      { Id: "35", Content: "Aswath M U, “Technical Specifications for Fine Aggregates”, Alternatives To River Sand - A Sustainable Approach, Seminar Document, ICI-KBC, Dec 2013, pp24-29" },
      { Id: "36", Content: "Aswath M U, “Concerns on the Use of Filtered Sand and Sea Sand for Construction”, Alternatives To River Sand - A Sustainable Approach, Seminar Document, ICI-KBC, Dec 2013, pp62-64" },
      { Id: "37", Content: "Aswath M U, “Ready Mixed Concrete Using Alternative Fine Aggregates And Quality Certification For Manufacturing”, Alternatives To River Sand - A Sustainable Approach, Seminar Document, ICI-KBC, Dec 2013, pp153-157" },
      { Id: "38", Content: "Aswath M U, “ Innovation and Behaviour for Associations”, Editorial, Bulletin of ACCE (I), Vol.12, No.2, July-September 2013" },
      { Id: "39", Content: "Aswath M U, “ New Quality and Certification standards for Ready Mixed Concrete Manufacturing by RMCMA, QCI and BMTPC”, Bulletin of ACCE (I), Vol.12, No.2, July-September 2013" },
      { Id: "40", Content: "Aswath M U, “ Alternatives to River Sand”, Bulletin of ACCE (I), Vol.12, No.2, July-September 2013" },
      { Id: "41", Content: "Aswath M U, “Manufactured Sand-A Perspective; Indian Construction-A Paradigm Shift” at Southern Convention & Builders’ Day 2013, Builders Association of India, Karnataka(Bangalore) Centre,7-8 November 2013, Bangalore" },
      { Id: "42", Content: "Raghavendra Y.B. and Aswath M.U., “Effect of self curing compound on the strength of high performance self compacting concrete”, The Indian Concrete Journal (ICJ), July 2013, pp45-49" },
      { Id: "43", Content: "Aswath M.U., “Power of Purpose-for organisations to grow”, Editorial Bulletin of ACCE (I), Vol.12, No.1, April-June 2013" },
      { Id: "44", Content: "Aswath M.U., “Role of Civil Engineers in Disaster Management”, Bulletin of ACCE (I), Vol.12, No.1, April-June 2013" },
      { Id: "45", Content: "Aswath M U, “Corporate Governance is the need of the Hour-Babu A Dhammanagi”,  an interview for  SME WORLD, Vol.VI, No.3, March 2013, ISSN 2319-1139" },
      { Id: "46", Content: "Aswath M U, “Paramount Nutritions Eyes Global Majors for Diversification-Two Visionary Entrepreneur Friends set outstanding Benchmarks to embrace Success”, SME WORLD, Vol.VI, No.3, March 2013, ISSN 2319-1139" },
      { Id: "47", Content: "Aswath M U, “ACCE in 2025, Realities and Dreams”, Editorial, Bulletin of ACCE (I), Vol.11, No.4, January-March 2013" },
      { Id: "48", Content: "Aswath M U, “We must learn lessons from failures and act”, Bulletin of ACCE (I), Vol.11, No.4, January-March 2013" },
      { Id: "49", Content: "V.R.Kowshika and Aswath M U, “Contribution of Admixtures in the Improvement of Lightweight Concrete”, Bulletin of ACCE (I), Vol.11, No.4, January-March 2013" },
      { Id: "50", Content: "Aswath M U, “Buildings for Freedom and some of the amazing Mud Buildings of the World”, Bulletin of ACCE (I), Vol.11, No.4, January-March 2013" },
      { Id: "51", Content: "Aswath M U, “Entrepreneurs Receive Rotary SME Awards for Excellence”, SME WORLD, Vol.VI, No.2, February 2013, ISSN 2319-1139" },
      { Id: "52", Content: "Aswath M U, “Cutting Across Sectors-Dhammanagi’s Success”, SME WORLD, Vol.VI, No.2, February 2013, ISSN 2319-1139" },
      { Id: "53", Content: "Aswath M U, “A Unique Endeavour-Rotary Club Bangalore South East Institutes SME Awards”, SME WORLD, Vol.VI, No.1, January 2013, ISSN 2319-1139" },
      { Id: "54", Content: "Aswath M U, “ Infrastructure Development and SMEs”, Editorial, ACCE(I) Bulletin, Vol.11, No.3,October-December 2012" },
      { Id: "55", Content: "V.R.Kowshika, Aswath M U, “Why organisations are where they are?”, ACCE(I) Bulletin, Vol.11, No.3,October-December 2012, PP 36-38" },
      { Id: "56", Content: "Aswath M U, “Guidelines on Quality Control and Quality Assurance of Ready Mix Concrete”, UltraTech Cement Limited, Bangalore, 9th January 2013" },
      { Id: "57", Content: "Aswath M.U.,  “Who Cares?”,  Editorial Bulletin of ACCE (I), Vol.11, No.2, July-September 2012" },
      { Id: "58", Content: "Aswath M.U.,  “Career Planning and  Management for a Civil Engineer”,   Bulletin of ACCE (I), Vol.11, No.1, April-June 2012" },
      { Id: "59", Content: "Aswath M.U.,  “Project Management: Importance and Necessity”,  Editorial Bulletin of ACCE (I), Vol.11, No.1, April-June 2012" },
      { Id: "60", Content: "Aswath M.U., Raghavendra Y.B., “Recent Developments in Self Curing”, Bulletin of ACCE (I), Vol.10, No.4, January-March 2012" },
      { Id: "61", Content: "Aswath M.U., “Cement and its Use”, Bulletin of ACCE (I), Vol.10, No.4, January-March 2012" },
      { Id: "62", Content: "Raghavendra Y.B ; Aswath M.U; “An Overview on the Usage of Alternative Materials in Concrete for Improvement of Long Term Strength and Durability Performance”, Recent Developments in Design and Construction Technologies for Repair, Rehabilitation and Retrofitting, 22nd, 23rd & 24th February 2012,National Seminar, Bangalore" },
      { Id: "63", Content: "Aswath M U, “ Towards prosperous India: Challenges for Engineers”, Editorial, ACCE(I) Bulletin, Vol.10, No.3,October-December 2011" },
      { Id: "64", Content: "Aswath M.U., Satish Chandar, “The Role & Importance of Passive Fire Protection Systems”,  Bulletin of ACCE (I), Vol.10, No.3, October-December 2011" },
      { Id: "65", Content: "Aswath M.U., “Ready-Mixed Concrete-Code of Practice and RMCMA Quality Scheme”,  Bulletin of ACCE (I), Vol.10, No.3, October-December 2011" },
      { Id: "66", Content: "Aswath M.U., “Quality Scheme for Ready-Mixed Concrete-RMCMA Quality Scheme”,  Bulletin of ACCE (I), Vol.10, No.2, April-September 2011" },
      { Id: "67", Content: "Aswath M.U, “Reinforcement Basics. How to stack, tie & bend and Various tests done on Reinforcement”, 6 Day’s In-house Training Programme for Gammon India  Civil Supervisors on Cement, Concrete and construction Technology dated 22nd  to 27th  August 2011, by Indian Concrete Institute - Karnataka Bangalore Centre at Bangalore" },
      { Id: "68", Content: "Aswath M.U.,  “How to Choose a Career in Civil Engineering” at Department of Civil Engineering, Global Academy of Technology, Bangalore as placement orientation program of ACCE(I), 17th July 2011" },
      { Id: "69", Content: "Arun Kumar, G.A.Satish & Dr.Aswath M U;  “Experimental Studies on Mortars and Shear Strength of Masonry Wall”, Bulletin of ACCE (I), Vol.9, No.4, April-December 2010" },
      { Id: "70", Content: "Aswath M U;  “Top Ten in Civil Engineering”, Bulletin of ACCE (I), Vol.9, No.4, April-December 2010" },
      { Id: "71", Content: "Smita Singh & Dr.Aswath M.U.; “Green Building Regulations”, on the occasion of 150th Year Birth Day celebrations of Sir M Visvesvaraya, Organised by The Institution of Engineers (I), Bangalore, 25th September, 2010" },
      { Id: "72", Content: "Dr.Aswath M.U., “Green Building Rating and Certification with a detailed note on GRIHA Rating System”, All India seminar on Eco Architecture and Green Buildings, Organised by The Institution of Engineers (I), Bangalore, 5th and 6th March , 2010" },
      { Id: "73", Content: "Aswath. M.U., “Necessity of Soft Skills for Consulting Civil Engineers” Bulletin of ACCE (I), Vol.9, No.3, Jan-Mar 2010" },
      { Id: "74", Content: "Aswath. M.U., “Volunteer Community Service Programs” Bulletin of ACCE (I), Vol.9, No.3, Jan-Mar 2010" },
      { Id: "75", Content: "Sashikanth P Kodi, Sunil M, Dr.Aswath M.U,; “Seismic Response of Transmission Line Towers Including the Effect of Soil Structure Interaction”, P:35, Recent Developments in Design and Construction Technologies, 28-30 January 2010,National Seminar, Bangalore" },
      { Id: "76", Content: "Murali H.S., Dr.Aswath M.U., “Shear Strength of Brick Masonry Wall Elements without openings”, P:32, Recent Developments in Design and Construction Technologies, 28-30 January 2010,National Seminar, Bangalore" },
      { Id: "77", Content: "Ravi, Dr.Aswath M.U.; “Finite Element Analysis of Masonry Walls with and without openings” , P:29, Recent Developments in Design and Construction Technologies, 28-30 January 2010,National Seminar, Bangalore" },
      { Id: "78", Content: "Ganesh Kumar.K, PG , Aswath. M.U., “An Experimental Investigation to Study the Compressive Strength of Hollow Clay Block Columns” PP:39-48, Bulletin of ACCE (I), Vol. No. 9 No. 2, October - December 2009" },
      { Id: "79", Content: "Aswath. M.U., “Continuous Learning”, Bulletin of ACCE (I), Vol. 9 No. 2, October - December 2009" },
      { Id: "80", Content: "Remya.R.Nair, Dr Aswath M.U, “Studies on Flexural Strength of Reinforced Hollow Clay Block Beams”,PP:12-17, Bulletin of ACCE (I), Vol. No. 9 No. 2, October - December 2009" },
      { Id: "81", Content: "Shamantha.H.M, Dr. Aswath. M.U. “Strength and Elastic Proper ties of Reinforced and Unreinforced Hollow Clay Block Column” ,PP:39-43, Bulletin of ACCE (I), Vol. No. 9 No. 1, July - September 2009" },
      { Id: "82", Content: "Dr.Aswath.M.U., “Challenges, Opportunities and Great Expectations!”, Bulletin of ACCE (I), Vol. No. 9 No. 1, July - September 2009" },
      { Id: "83", Content: "Adanagouda, Dr.Aswath.M.U., “Load Carrying Capacity of Hollow Clay Block Masonry Columns - An Experimental Study”,PP:33-38, Bulletin of ACCE (I), Vol. No. 9 No. 1, July - September 2009" },
      { Id: "84", Content: "Dr. Aswath M.U., “Energy Efficiency-Green Buildings & Certification” PP:6-9, Bulletin of ACCE (I) Vol. No. 9 No. 1, July - September 2009" },
      { Id: "85", Content: "Dr. Aswath M.U., Sreenivas S.R., “Slurry Infiltrated Fibrous Concrete (SIFCON)-an Experimental Study”  PP: 6-10 Bulletin of ACCE (I) April - June 2009" },
      { Id: "86", Content: "Smita Sing*, Manjunath.S. & Aswath. M.U. “Experimental Investigation on the Behaviour of Composite Column Under Axial Compression” 231-239, Recent Developments in Design and Construction Technologies, 7-9 August 2008, Bangalore" },
      { Id: "87", Content: "T.Raghavendra, M.Selvaraj & M.U.Aswath, “Computer Aided Analysis  and Structural Optimization of Transmission Line Tower” 246-257, Recent Developments in Design and Construction Technologies, 7-9 August 2008,Bangalore" },
      { Id: "88", Content: "Smita Sing, Raghavendra* & Aswath. M.U. “Infrastructure Planning, Design and Management: Emerging Trends” - National seminar on “ Restructuring of Education to Meet the Global Industrial Needs” on 17th & 18th November 2006, organized by ISTE at BIT, Bangalore" },
      { Id: "89", Content: "Anzer.A, M.U.Aswath, G.A.Satish “Behaviour of Reinforced High Volume Fly Ash Concrete Slabs Under Flexure –An Experimental Investigation”, 221-232, Recent Developments in Design and Construction Technologies 18-20 January 2007, Bangalore" },
      { Id: "90", Content: "Aswath M U, “Fibre Reinforced Concrete and Its Uses”, Advances in Cementetious Materials & Testing-sponsored by AICTE and ISTE -, M.S.Ramaiah Institute of Technology, 14-28th  March 1998, Bangalore" },
      { Id: "91", Content: "Aswath M.U, “ Civil Engineering Profession of Tomorrow” at Department of Civil Engineering, K.V.G. College of Engineering, Sullia as placement orientation program of ACCE(I)" },
    ]

    const PublishedInMedia = [
      {Id: "1", Content: "“Proper System Needed To Check Violations” The Hindu, Saturday, March 6th 2010, Build Forum, the public query column of property Plus-THE HINDU invites questions related to technical/structural/civil aspects of buildings. The questions will be answered by professionals connected to the Association of Consulting Civil Engineers (I). The readers can send the queries to ranju@thehindu.co.in , I am giving below the details of the articles written by me and published in The Hindu-Property plus. Some of the online links are provided below for further reading."},
      {Id: "2", Content: "Safety first, always: Stability of high-rise buildings depends on factors such as height, width and configuration of the structure, March 12, 2011, http://www.hindu.com/pp/2011/03/12/stories/2011031250280200.html, http://www.hindu.com/pp/2011/03/12/stories/2011031250270200.html"},
      {Id: "3", Content: "Ideal designs need collaborative efforts: Beams that are visible inside homes are not commentaries on buildings being unsafe. They form part of the structural get-up of a design, march 26, 2011 http://www.hindu.com/pp/2011/03/26/stories/2011032650440200.html"},
      {Id: "4", Content: "Safe design of staircase: April 2, 2011 http://www.hindu.com/pp/2011/04/02/stories/2011040250470200.html"},
      {Id: "5", Content: "No ceiling for ceiling heights: A high ceiling is an inspiration, improves productivity and provides a strange sense of freedom, April 9, 2011, http://www.hindu.com/pp/2011/04/09/stories/2011040950410300.htm"},
      {Id: "6", Content: "For the right plumbing techniques: Improper plumbing designs cause leakages in pipes and damage the building, April 16, 2011, http://www.hindu.com/pp/2011/04/16/stories/2011041650320200.htm"},
      {Id: "7", Content: "Passing on a new look, making a style statement: The building envelope design must include structural integrity, moisture/temperature/noise control and regulation of air flow. An article related to building cladding, April 23, 2011, http://www.hindu.com/pp/2011/04/23/stories/2011042350300200.htm"},
      {Id: "8", Content: "Bamboo adds to the building strength: with earthquakes occurring frequently, the basic care that should be taken is in the material that we use in construction. An article related to bamboo in building industry,  April 30,2011, http://www.hindu.com/pp/2011/04/30/stories/2011043050520300.html"},
      {Id: "9", Content: "Traditional mason, deft hands and some imagination: Red-oxide flooring isn’t outdated as such. It only needs perfection to throw across the same gleaming floors. Some details. May 7, 2011 ,http://www.hindu.com/pp/2011/05/07/stories/2011050750370200.html ,http://www.hindu.com/pp/2011/05/07/stories/2011050750360200.html"},
      {Id: "10", Content: "Go for natural ventilation and light: Every building should be designed for clean air movement, thus creating the ideal indoor climate. May 14,2011, http://www.hindu.com/pp/2011/05/14/stories/2011051450440200.html, http://www.hindu.com/pp/2011/05/14/stories/2011051450480200.html, http://www.hindu.com/pp/2011/05/14/stories/2011051450450200.html"},
      {Id: "11", Content: "This earthy material can withstand earthquakes: wood is versatile, with a well-established tradition of being affordable and durable, if properly seasoned. May 21, 2011, http://www.hindu.com/pp/2011/05/21/stories/2011052150450200.html"},
      {Id: "12", Content: "Good construction practices reflect in the finishing: hairline cracks are symptoms of possible problems. Proper diagnosis and treatment will ensure safety. May 28, 2011, http://www.hindu.com/pp/2011/05/28/stories/2011052850350200.html, http://www.hindu.com/pp/2011/05/28/stories/2011052850340200.html"},
      {Id: "13", Content: "Peaceful streaks of natural light, Skylights admit more light per unit area than windows and distribute it evenly over a space. Some details. JULY 9,2011, The Hindu-Property Plus page-3, http://www.thehindu.com/todays-paper/tp-features/tp-propertyplus/article2231653.ece, http://www.thehindu.com/todays-paper/tp-features/tp-propertyplus/article2212531.ece"},
      {Id: "14", Content: "Being Civil: We are in an age of mechanisation, August 10, 2010, new Indian Express,"},
      {Id: "15", Content: "Conversation with Jose Torero: Tear down a building if you must. Wouldn’t you rather save lives?, August 17,2010 Bangalore Mirror, http://www.bangaloremirror.com/article/10/20100817201008170459581281fbdab0e/%E2%80%98Tear-down-a-building-if-you-must-Wouldn%E2%80%99t-you-rather-save-lives%E2%80%99.html"},
      {Id: "16", Content: "Charting a green future: The low-down on green Buildings, construction practices, energy audits and the variety of certification programmes involved in the process, August 20,2010, Deccan Herald, http://www.deccanherald.com/content/89888/charting-green-future.html"}
    ]

    return (
      <div>
        {/* Header */}
        <Header />

        {/* Banner */}
        <Banner image="banner/banner03.jpg" />

        <div className="container section-title with-desc clearfix paddingtop-2">
          <div className="title-header"><h4 className="title">Books Published</h4></div>
          <ul className="ttm-list ttm-list-style-icon">
            <table class="table">
              <tbody>
                <tr>
                  <th style={{ width: "5%" }}>Sl</th>
                  <th style={{ width: "20%" }}>Date</th>
                  <th style={{ width: "75%" }}>Details</th>
                </tr>
                {
                  BooksPublished.map((item, i) => (
                    <tr>
                      <td>{item.Id}</td>
                      <td>{item.Header}</td>
                      <td>{item.Content}</td>
                    </tr>
                  ))
                }
              </tbody>
            </table>
          </ul>
        </div>

        <div className="container section-title with-desc clearfix"><br />
          <div className="title-header"><h4 className="title">International Journals / Conferences</h4></div>
          <ul className="ttm-list ttm-list-style-icon">
            {
              InternationalJournals.map((item, i) => (
                <li><i className="fa fa-check-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content">{item.Content}</span></li>
              ))
            }
          </ul>
        </div>

        <div className="container section-title with-desc clearfix"><br />
          <div className="title-header"><h4 className="title">National Journals / Conferences</h4></div>
          <ul className="ttm-list ttm-list-style-icon">
            {
              NationalJournals.map((item, i) => (
                <li><i className="fa fa-check-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content">{item.Content}</span></li>
              ))
            }
          </ul>
        </div>
        
        <div className="container section-title with-desc clearfix"><br />
          <div className="title-header"><h4 className="title">Projects / Articles / Interviews Published in Media</h4></div>
          <ul className="ttm-list ttm-list-style-icon">
            {
              PublishedInMedia.map((item, i) => (
                <li><i className="fa fa-check-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content">{item.Content}</span></li>
              ))
            }
          </ul>
        </div>

        {/* Footer */}
        <Footer />
      </div>
    )
  }
}
